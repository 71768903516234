import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../../../../../context/AuthContext';
import { EnumMeetingStatus } from '../../../../../../../../../enum/EnumMeetingStatus';
import { Meeting } from '../../../../../../../../../types/dashboard';
import { statusMeeting } from '../../../../../../../../../util/meeting';
import { MODAL_TYPES } from '../../../type/modalTypes';
import Button from '../atomic_components/Button';
interface Props {
  isDisabled?: boolean;
  handleMeetingSelected: (x: any) => void
  meeting: Meeting & { status: string, assignedTo: string }
  setOpenModal: (x: any) => void,
  isSelf?: boolean
};

const CancelAction = (props: Props) => {
  const authContext = useContext(AuthContext);
  const userCode = authContext?.account?.profile?.code;
  const isSelfAssigned = props.meeting.assignedTo === userCode

  const { t } = useTranslation();
  const isScheduled = props.meeting.status === t(statusMeeting(EnumMeetingStatus.SCHEDULED) );

  const onClick = (e: any) => {
    e.preventDefault();
    props.handleMeetingSelected(props.meeting);
    props.setOpenModal({
      type: MODAL_TYPES.DELETE,
      isOpen: true,
    });
  }

  return (<Button
    disabled={!isScheduled || props.isDisabled || (props.isSelf && !isSelfAssigned)}
    onClick={onClick}
    icon={"cancel"}
  />);
};

export default CancelAction;
