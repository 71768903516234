import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";

import { IUsersAdapter } from "../../../models/user.models";
import { EMeetingStatus, IEventDetailAdapter } from "../../../models/event.models";
import { getNameEmployeeFromMeeting } from "../controllers/area_service_details.controller";

import AreaAdministrationServiceDetailsMeetingModal from './AreaAdministrationServiceDetailsMeetingModal';
import AreaAdministrationServiceDetailsMeetingCancel from './AreaAdministrationServiceDetailsMeetingCancel';

import { Button, Icon, Popup } from "semantic-ui-react";
import ModalDocumentMeeting from "../../Dashboard/components/Modals/ModalDocumentMeeting";

interface IProps {
    meeting: IEventDetailAdapter;
    users: IUsersAdapter[];
    service: string;
    backoffice: string;
    shared: string;
    setReload: () => void;
}

const AreaAdministrationServiceDetailsMeetingsActions = ({ meeting, users, service, backoffice, shared, setReload }: IProps) => {
    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const [isDocumentationModalOpen, setIsDocumentationModalOpen] = useState<boolean>(false);

    // Verificar si meeting.Code está definido
    if (!meeting.Code) {
        console.error("meeting.Code is undefined", meeting);
    }

    return (
        <>
            <Popup content={meeting.Comment} trigger={
                <Button icon color={meeting.Comment !== null && meeting.Comment.length > 0 ? "red" : undefined} disabled={meeting.Comment === null || meeting.Comment.length === 0} >
                    <Icon name="commenting" />
                </Button>
            } />

            <Popup header={"Funcionari"} content={getNameEmployeeFromMeeting(meeting, users)} trigger={
                <Button icon circular color="green" disabled={!getNameEmployeeFromMeeting(meeting, users)}>
                    <Icon name="user" />
                </Button>
            } />

            {meeting.Status !== EMeetingStatus.CANCELLED && (
                <AreaAdministrationServiceDetailsMeetingModal meeting={meeting} users={users} backoffice={backoffice} shared={shared} setReload={setReload} />
            )}
            <AreaAdministrationServiceDetailsMeetingCancel meeting={meeting} shared={shared} setReload={setReload} />


            {(
                
                <ModalDocumentMeeting
                    meeting={meeting}
                    isSelf={!meeting.isDocumentationAvailable}
                />
            )}
        </>
    );
}

export default AreaAdministrationServiceDetailsMeetingsActions;
