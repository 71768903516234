import React, { FC, ReactElement, useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullCalendar, { CalendarApi, CalendarOptions, DayHeader } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { selectSlotsCalendarMonth } from "../../assets/slots";
import { useTranslation } from "react-i18next";
import { Dimmer, Loader } from "semantic-ui-react";
import moment from "moment";
import 'moment/locale/ca';
import i18next from "i18next";
import {fetchDeleteSlotById} from "../../assets/slots.ts";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import caLocale from '@fullcalendar/core/locales/ca';
import esLocale from '@fullcalendar/core/locales/es';
import i18n from "../../configs/i18n";
import BOTableDisponibilitatSlotDelete from "./BOTableDisponibilitatSlotDelete/BOTableDisponibilitatSlotDelete";



export type SlotCalendar = {
  id?: string;
  title?: string;
  start: string;
  end: string;
  display?: string;
  eventColor?: string;
  backgroundColor?: string;
  textColor?: string;
  color?: string;
  roomCount?: number;
  roomReservedCount?: number;
  serviceCode?: string;
};
interface BOCalendarProps {
  loading: boolean;
  calendarRef: React.RefObject<FullCalendar>;
  handleEventsApi: Function;
  forceChacheRef?: any;
}
const BOCalendar: FC<BOCalendarProps> = ({
  loading,
  calendarRef,
  handleEventsApi,
  forceChacheRef,
}: BOCalendarProps): ReactElement => {
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;
  const { t } = useTranslation();
  const slotMonth = useSelector(selectSlotsCalendarMonth);
  const [eventsSlot, setEventsSlot] = useState<SlotCalendar[]>([]);
  const [cache, setCache] = useState<any>({});
  const [openDelete, setOpenDelete] = useState<any>(false);
  const [slot, setSlot] = useState<string>("");
  const [date, setDate] = useState<any>();
  const [serviceCode, setServiceCode] = useState<string>("");

  const handleOpenDelete = (e: boolean) => {
    setOpenDelete(e);
  };

  // New function to handle slot deletion
  const handleSlotDeleted = (slotId: string) => {
    setEventsSlot((prevEvents) => prevEvents.filter((event) => event.id !== slotId));
  };

  const renderEventContent = (eventInfo: any) => {
    const { event } = eventInfo;
    if (event.title) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "3px",
            fontSize: "12px",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <div
            className="fc-daygrid-event-dot"
            style={{
              border: "4px solid " + event.backgroundColor,
            }}
          ></div>
          <span dangerouslySetInnerHTML={{ __html: eventInfo.timeText + "" }}></span>
          <strong dangerouslySetInnerHTML={{ __html: event.title }}></strong>
          {eventInfo.timeText !== "" ? (
            <button
              onClick={() => handleDeleteEvent(event)}
              style={{
                marginLeft: "auto",
                padding: "2px 5px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "3px",
                cursor: "pointer",
                fontSize: "10px",
              }}
            >
              🗑
            </button>
          ) : null}
        </div>
      );
    }
  };

  const handleDeleteEvent = async (eventId: any) => {
    if (eventId._def.extendedProps.roomReservedCount === 0) {
      let deleteResponse = await dispatch(fetchDeleteSlotById(eventId._def.extendedProps.serviceCode, eventId._def.publicId, false, token));
      if(deleteResponse.response){
        setEventsSlot(eventsSlot.filter((item) => item.id !== eventId.id)); // Update immediately
        toast((
          `${t("message.availability.delete.success_01")}`
        ), { type: "success" });
      }
      else{
        toast(t("message.availability.delete.error"), { type: "error" });
            }
    } else {
      setServiceCode(eventId._def.extendedProps.serviceCode);
      setSlot(eventId._def.publicId);
      setDate(eventId.start);
      setOpenDelete(true);
    }
  };

  const language = i18next.language;
  moment.locale(language);

  const restartCache = () => {
    setCache({});
  };

  useEffect(() => {
    const date = moment(calendarRef.current?.getApi().getDate()).format("YYYY-MM");
    setCache({ ...cache, [date]: eventsSlot });
  }, [eventsSlot]);

  useEffect(() => {
    const c = calendarRef.current?.getApi();
    if (c !== undefined) {
      handleEventsApi(c);
    }
    forceChacheRef(restartCache);
  }, []);

  const getEvents = async (c: CalendarApi) => {
    const date = moment(c.getDate()).format("YYYY-MM");
    const cacheSlot = cache[date];
    if (cacheSlot) {
      setEventsSlot(cacheSlot);
    } else {
      await handleEventsApi(c);
    }
  };

  useEffect(() => {
    setEventsSlot(slotMonth);
  }, [slotMonth]);

  return (
    <div id={"container-fullcalendar"} className={"t"}>
      <div className={`tt ${loading ? "sise" : "none"}`}>
        <Dimmer active inverted>
          <Loader size={"massive"}>Loading</Loader>
        </Dimmer>
      </div>
      <FullCalendar
        viewClassNames={`${loading ? "blur-loader" : ""}`}
        ref={calendarRef}
        customButtons={{
          customNext: {
            text: ">>",
            click: async () => {
              const c = calendarRef.current?.getApi();
              if (c !== undefined) {
                c.next();
                await getEvents(c);
              }
            },
          },
          customToday: {
            text: t("service_availability.today"),
            click: async () => {
              const c = calendarRef.current?.getApi();
              if (c !== undefined) {
                c.today();
                await getEvents(c);
              }
            },
          },
          customPrev: {
            text: "<<",
            click: async () => {
              const c = calendarRef.current?.getApi();
              if (c !== undefined) {
                c.prev();
                await getEvents(c);
              }
            },
          },
        }}
        locale={
          i18next.language === "ca"
            ? caLocale
            : i18next.language === "es"
            ? esLocale
            : i18next.language === "oci"
            ? caLocale
            : undefined
        }
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "customPrev customToday customNext",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        buttonText={{
          today: t("service_availability.today"),
          month: t("service_availability.month"),
          week: t("service_availability.week"),
          day: t("service_availability.day"),
        }}
        initialView={"dayGridMonth"}
        dayMaxEvents={1}
        events={eventsSlot}
        slotLabelFormat={{
          hour: "2-digit",
          hour12: true,
        }}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }}
        eventContent={renderEventContent}
      />
      <BOTableDisponibilitatSlotDelete
        serviceCode={serviceCode}
        slotId={slot}
        date={date}
        openDelete={openDelete}
        handleDelete={handleOpenDelete}
        onSlotDeleted={handleSlotDeleted} // Pass the callback
      />
    </div>
  );
};

export default BOCalendar;