import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../context/AuthContext";
import { LayoutContext } from "../../context/LayoutContext";

import Layout from "../Layout/LayoutContainer";
import { Button } from "../Button/Button";

import { downloadRecordingsCSV, getAreaInfo, getRecordingDetails } from "../../assets/api";

import { toast } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import InputRecording from "../InputRecording";
import DropBoxContainer from "./components/DropBoxContainer";
import DropDownAreas from "../DropDownAreas";
import DropDownServices from "../DropDownServices";

import Table from "./components/Table";
import DropDownOrganization from "../DropDownOrganization";
import { defaultRecordingsFormatted, Recordings, RecordingsFormatted } from "./types";
import { formatRecording, formatRecordingData } from "./format/recording";
import { getAreasFromOrganization } from "../../assets/personalitzation";
import { Area, Service } from "../../types/area";
import { defaultAreaInfo } from "../../models/AreaInfo";
import { fetchGetAreasServicesApi, getAreasServicesApi, getAreasServicesApiDropped } from "../../assets/areas";
import { dispatch } from "rxjs/internal/observable/pairs";
import { useDispatch } from "react-redux";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { getReasonCodesMap } from "../../models/ReasonCodesMap";

import moment from "moment";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import i18n from "../../configs/i18n";

const getTodayStartUTC = () => {
  const today = new Date();
  const date = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0));
  const dateWithSeconds = date.setHours(0, 0, 0, 0);
  return dateWithSeconds;
};
const getTodayEndUTC = () => {
  const today = new Date();
  const date = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));
  const dateWithSeconds = date.setHours(23, 59, 59, 999);
  return dateWithSeconds;
};

const RecordingDetails = (props: any) => {
  const authContext = useContext(AuthContext);
  const { setTitle, setBreadcrumb } = useContext(LayoutContext);

  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [tokenContinuation, setTokenContinuation] = useState<any>("");
  const [backTokenList, setBackTokenList] = useState<any>({});
  const [maxItemsPerRow] = useState<number>(10);
  const [recordingsFormatted, setRecordingsFormatted] = useState<RecordingsFormatted[]>([defaultRecordingsFormatted]);
  const [form, setForm] = useState<any>({ dateStart: "", dateEnd: "", organizations: "", area: "", service: "" });

  const [dateStart, setDateStart] = useState(getTodayStartUTC());
  const [dateEnd, setDateEnd] = useState(getTodayEndUTC());

  const areaCode = props.match?.params?.areaCode;
  const initialHistory = props.match?.path?.split("/")[1];
  const initialHistoryPath = "/" + initialHistory;

  const historyHandleClick = () => {
    history.push(initialHistoryPath);
  };

  const [buttons, setButtons] = useState([
    {
      icon: "arrow left",
      message: "go_back",
      onClick: historyHandleClick,
      disabled: false
    },
  ]);

  useEffect(() => {
    setTitle(t("recordings.title"));
    setBreadcrumb([
      {
        name: t(`breadcrump.${initialHistory}`),
        link: initialHistory,
      },
      {
        name: t("recordings.title"),
      },
    ]);

    const initialOrganization = getOrganization();

    if (!initialOrganization) {
      getServices(areaCode);
    } else {
      if (!areaCode) {
        getAreas(initialOrganization);
        areas.map(area => {
          getServices(area.code);
        })
      } else {
        getServices(areaCode);
      }
    }

    setLoading(false);
  }, [t, setTitle, setBreadcrumb]);

  useEffect(() => {
    if (loading) {

      if (organizations.length === 0) {
        setAreas([]);
        setServices([]);
      }
      else
        organizations.map(organization => {
          getAreas(organization);
        })
    }
  }, [organizations])

  const getOrganization = () => {
    if (areaCode) return authContext.account.profile.organization;
    else return props.match?.params?.organitzationCode;
  };

  const getAreas = async (organizationInitial: string) => {
    const { backoffice } = await authContext.getTokenForScopes();

    getAreasFromOrganization(organizationInitial, backoffice)
      .then(response => response.json())
      .then((response: Area[]) =>{
        setAreas(response)})
  }

  const getServices = async (area: string) => {
    const { backoffice } = await authContext.getTokenForScopes();
    if (area === "") {
      setForm({ ...form, ["service"]: "" })
    }
    else {
      getAreasServicesApiDropped(area, backoffice)
        .then(response => setServices(response))
      if (areaCode !== undefined) setForm({ ...form, ["area"]: areaCode })
    }
  }

  const formIsValidate = () => {
    if (dateStart === null || dateEnd === null) {
      toast(t("recordings.no_date"), { type: "error" });
      return false;
    }

    if (dateStart > dateEnd === true) {
      toast(t("recordings.incorrect_date"), { type: "error" });
      return false;
    }
    if ((!form.organizations && !getOrganization()) || (!getOrganization() && form.organizations.length === 0)) {
      toast(t("recordings.no_organizations"), { type: "error" });
      return false;
    }

    return true;
  }

  const getToken = (token: any, isForward: boolean) => {
    if (!isForward) {
      try {
        token = backTokenList[backTokenList[token]];
      } catch (error) {
        token = "";
      }
    }
    return token.length > 0 ? JSON.stringify(token).replace(/\"/g, "") : "";
  };

  const getRecordings = async (isForward: boolean, useContinuationToken: boolean) => {
    if (formIsValidate()) {
      setLoading(true);
      const { backoffice } = await authContext.getTokenForScopes();
      let token;

      if (useContinuationToken)
        token = getToken(tokenContinuation, isForward);
      else
        token = getToken("", isForward);

      const furmulario: any = getForm();

      const { key, value } = await getRecordingDetails(
        furmulario,
        maxItemsPerRow,
        token,
        backoffice
      ).then((response) => {
        if (response.status == "204") {
          setLoading(false)
          return { key: "", value: [] }
        }
        if (response.status == "400") {
          setLoading(false)
          return { key: "", value: [] }
        }
        return response.json();
      });

      if (value.length === 0) {
        setLoading(false)
        toast(t("recordings.error_data_not_found"), { type: "info" });
      }
      else {
        setLoading(false)
        const recordings: Recordings[] = value;

        setBackTokenList({ ...backTokenList, [key]: token });
        setTokenContinuation(key);

        const recordingsFormattedResult: RecordingsFormatted[] = recordings.map(formatRecordingData);

        setRecordingsFormatted(recordingsFormattedResult);
        setLoading(false)
        setButtons([
          {
            icon: "arrow left",
            message: "go_back",
            onClick: historyHandleClick,
            disabled: false
          },
          {
            icon: "file",
            message: t("recordings.csv"),
            onClick: generateCSV,
            disabled: false
          },
        ]);

        return recordingsFormattedResult;
      }
    }
  }

  const getForm = () => {
    {
      const furmulario = {
        organizations: form.organizations,
        area: form.area,
        service: form.service,
        dateStart: moment(dateStart).format('YYYY-MM-DDTHH:mm:ss'),
        dateEnd: moment(dateEnd).format('YYYY-MM-DDTHH:mm:ss'),
      };
      return furmulario
    };
  }
  const generateCSV = async () => {
    setButtons([
      {
        icon: "arrow left",
        message: "go_back",
        onClick: historyHandleClick,
        disabled: false
      },
      {
        icon: "file",
        message: t("recordings.csv"),
        onClick: generateCSV,
        disabled: true
      },
    ]);
    const { backoffice } = await authContext.getTokenForScopes();
    const token = getToken("", true);
    const furmulario: any = getForm();

    const responseRecordingDetails = await getRecordingDetails(furmulario, 1000, token, backoffice);
    const { value } = await responseRecordingDetails.json();
    const recordings: Recordings[] = value;

    const reasonCodesMap = getReasonCodesMap(t);
    const result = recordings.map(recording => {
      const formattedRecording = formatRecordingData(recording);
      formattedRecording.reason = reasonCodesMap[formattedRecording.reason] || formattedRecording.reason;
      return formatRecording(formattedRecording, t);
    });


    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Recording Details",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "RecordingDetails",
    };



    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(result);
    setButtons([
      {
        icon: "arrow left",
        message: "go_back",
        onClick: historyHandleClick,
        disabled: false
      },
      {
        icon: "file",
        message: t("recordings.csv"),
        onClick: generateCSV,
        disabled: false
      },
    ]);
  }

  const CustomInput = ({ value, onClick, placeholder }: any) => (
    <div className="date-picker-input" onClick={onClick}>
      <span className="date-picker-value">{value || placeholder}</span>
      <FaCalendarAlt className="date-picker-icon" />
      <style>
        {`
          .date-picker-input {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 9px;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: #fff;
            width: 100%;
            height: 100%;
            min-height: 41px;
          }
  
          .date-picker-value {
            margin-right: 5px;
          }
  
          .date-picker-icon {
            margin-left: auto;
          }
          .react-datepicker {
            font-size: 1em;
          }
  
          .react-datepicker__month {
            margin: 0.4em 1em;
          }
          .react-datepicker__day-name, .react-datepicker__day {
            width: 1.9em;
            line-height: 1.9em;
            margin: 0.166em;
          }
          .react-datepicker__current-month {
            font-size: 1em;
          }
          .ui.loader {
           margin-top: 75px;
        }
        `}
      </style>
    </div>
  );

  const handleDateStartChange = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    const dateWithSeconds = utcDate.setHours(0, 0, 0, 0);
    setDateStart(dateWithSeconds);
  };

  const handleDateEndChange = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    const dateWithSeconds = utcDate.setHours(23, 59, 59, 999);
    setDateEnd(dateWithSeconds);
  };


  return (
    <>
      <Layout.Main>
        <div className="d-flex flex-wrap flex-lg-nowrap justify-content-between">
          <div className="w-100 mr-4">
            <div className="d-flex justify-content-between">
              <div className="w-50 mr-2">
                <label>{t("recordings.start")}</label>
                <DatePicker
                  selected={dateStart}
                  onChange={handleDateStartChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText={t("home.createModal.select_date")}
                  customInput={<CustomInput />}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={i18n.language}
                />
              </div>
              <div className="w-50">
                <label>{t("recordings.end")}</label>
                <DatePicker
                  selected={dateEnd}
                  onChange={handleDateEndChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText={t("home.createModal.select_date")}
                  customInput={<CustomInput />}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={i18n.language}
                />
              </div>
            </div>
            {!getOrganization() && (
              <DropDownOrganization
                header={t("recordings.organization")}
                styleContainer={{ width: "45%" }}
                onChange={(organizations: string[]) => {
                  setOrganizations(organizations);
                  setForm({ ...form, ["organizations"]: organizations })
                  if (organizations.length>0){
                    getAreas(organizations[0])
                  }
                }}
              />
            )}
            <DropBoxContainer header={t("recordings.more_filters")}>
              <div className="d-flex flex-wrap">
                {!areaCode && (
                  <DropDownAreas
                    header={t("recordings.area")}
                    areas={areas}
                    styleContainer={{ width: "45%" }}
                    classNameContainer="mr-4"
                    onChange={(area) => {
              
                      if (area !== "") {
                        getServices(area);
                      }
                      else {
                        setServices([]);
                      }
                      setForm({ ...form, ["area"]: area, ["service"]: "" })
                    }}
                  />
                )}

                <DropDownServices
                  header={t("recordings.service")}
                  services={services}
                  styleContainer={{ width: "45%" }}
                  onChange={(service) => {
                    setForm({ ...form, ["service"]: service })
                  }}
                />
              </div>
            </DropBoxContainer>
          </div>
          <button
            className="btn btn-danger mt-4"
            style={{ height: 50 }}
            onClick={async () => {
              await setRecordingsFormatted([defaultRecordingsFormatted]);
              setTokenContinuation("");
              getRecordings(true, false)
            }}
            disabled={loading}
          >
            {t("recordings.search")}
          </button>
        </div>

        {loading ? (
          <div style={{  height: "250px" }}>
  <Loader size="large" active={loading}>
    {t("loading.defect")}
  </Loader>
  </div>
) : (
  recordingsFormatted[0].meetingID.length !== 0 && (
    <>
      <div className="d-flex justify-content-end">
        {backTokenList[tokenContinuation] && (
          <button
            className="btn btn-danger mt-4 mr-2"
            onClick={() => getRecordings(false, true)}
          >
            {t("recordings.previous")}
          </button>
        )}
        {tokenContinuation && (
          <button
            className="btn btn-danger mt-4"
            onClick={() => getRecordings(true, true)}
          >
            {t("recordings.next")}
          </button>
        )}
      </div>
      <div className="table-responsive mt-5 px-3">
        <Table
          data={recordingsFormatted}
          translationHeader={(text: string) => t(`recordings.${text}`)}
          loading={loading}
        />
      </div>
    </>
  )
)}

     

      </Layout.Main>


      <Layout.Article>
        {buttons.map((button) => (
          <><Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
            disabled={button.disabled} />
            {button.disabled && <Segment padded={"very"}>
              <Dimmer active inverted size={"massive"}>
                <Loader size="large">{t("loading.defect")}</Loader>
              </Dimmer>
            </Segment>}</>
        ))}

      </Layout.Article>
    </>
  )
}

export default RecordingDetails