import React, { Fragment, ReactNode, FC } from "react";
import { EnumRol } from "../../enum/EnumRol";
import { Header } from "./Header";
interface MainProps {
  children?: ReactNode;
  style?: React.CSSProperties;
}
interface ArticleProps {
  children?: ReactNode;
}
interface FullScreenProps {
  children?: ReactNode;
}

interface ILayoutContainer {
  FullScreen: FC<FullScreenProps>;
  Main: FC<MainProps>;
  Article: FC<ArticleProps>;
}

interface LayoutContainerProps {
  children?: ReactNode;
  userRoles: EnumRol[];
}

const FullScreen: FC<MainProps> = (props) => (
  <div className="col-md" id="main" style={props.style}>
    {props.children}
  </div>
);

const Main: FC<MainProps> = (props) => (
  <div className="col-md-8" id="main">
    <div className="card border-0">
      <div className="card-body">{props.children}</div>
    </div>
  </div>
);

const Article: FC<ArticleProps> = (props) => (
  <div className="col-md-4" id="article">
    <div className="card border-0">
      <div className="card-body">{props.children}</div>
    </div>
  </div>
);

const LayoutContainer: React.FC<LayoutContainerProps> & ILayoutContainer = ({
  children,
  userRoles,
}: LayoutContainerProps) => {
  return (
    <Fragment>
      <Header userRoles={userRoles} />
      <div className="row justify-content-center bg-white p-5">{children}</div>
    </Fragment>
  );
};

LayoutContainer.FullScreen = FullScreen;
LayoutContainer.Main = Main;
LayoutContainer.Article = Article;

export default LayoutContainer;
