import React from "react";
import { Button, Popup } from "semantic-ui-react";
import BOPopupUser from "../../../../../BOTableMeetingQueue/BOPopupUser/BOPopupUser";
import BOPopupDocumentation from "../../../../../BOTableMeetingQueue/BOPopupDocumentation/BOPopupDocumentation";
import { CONTENT_MEETING_SUB_TYPE } from "../../type/contentMeeting";
import CancelAction from "./components/CancelAction";
import MoreInfoAction from "./components/MoreInfoAction";
import RefuseAction from "./components/RefuseAction";
import CommentAction from "./components/CommentAction";
import SelfAssignAction from "./components/SelfAssignAction";
import DownloadInfoAction from "./components/DownloadInfoAction";
interface Props {
  meeting: any;
  handleMeetingSelected: (x: any) => void;
  setOpenModal: (x: any) => void;
  contentType?: string[];
  isDisabled?: boolean;
  servicesRefresh?: () => void;
  timeStatus?: any
}

const ActionsCell = (props: Props) => {
  
  const isDocumentViewerActive = true; // Si es 'True', el icono será rojo, sino, aparecerá en gris

  // Función para manejar la acción del visor de documentos
  const handleDocumentViewer = () => {
    console.log("Abrir visor de documentos");
  };
console.log("props.isDisabled",props.isDisabled)
console.log("props.timeStatus.negative", props.timeStatus.negative)
  return (
    <div className={"d-flex justify-content-center"}>

      { props.meeting.comment !== null ? 
        <>
          { props.meeting.comment.length > 0 ? 
              <Popup content={props.meeting.comment} trigger={<Button color={"red"} size={"small"} icon='commenting' />} /> 
            :
              <Button disabled color={"grey"} size={"small"} icon='commenting' />
          }  
        </>
      :
        <Button disabled color={"grey"} size={"small"} icon='commenting' />
      }

      {props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.MORE_INFO) && (
        <MoreInfoAction meeting={props.meeting} />
      )}



{props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.ASSIGNED_ATTENDERS) && (
        <BOPopupUser meeting={props.meeting} servicesRefresh={props.servicesRefresh} />
      )}

      {props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.SELF_ASSIGN) && (
        <>
          {/* { !props.timeStatus.negative && ( */}
            <SelfAssignAction timeStatus={props.timeStatus} meeting={props.meeting} isDisabled={props.timeStatus.negative} servicesRefresh={props.servicesRefresh} />
          {/* )} */}
        </>
      )}

      {(props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.REFUSE) || props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.REFUSE_SELF)) && (
        <RefuseAction timeStatus={props.timeStatus} handleMeetingSelected={props.handleMeetingSelected} meeting={props.meeting} setOpenModal={props.setOpenModal} isSelf={props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.REFUSE_SELF)} />
      )}

      {(props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.CANCEL) || props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.CANCEL_SELF))  && (
        <>
          <CancelAction isDisabled={props.timeStatus.negative} handleMeetingSelected={props.handleMeetingSelected} meeting={props.meeting} setOpenModal={props.setOpenModal} isSelf={props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.CANCEL_SELF)} />
        </>
      )}

      {props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.COMMENT_INFO) && (
        <CommentAction meeting={props.meeting} />
      )}

      {props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.DOWNLOAD_INFO) && (
        <DownloadInfoAction meeting={props.meeting} />
      )}


      {(props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.DOCUMENT) || props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.DOCUMENTS_SELF)) 
 && (
        <BOPopupDocumentation  meeting={props.meeting} isSelf={props.contentType?.includes(CONTENT_MEETING_SUB_TYPE.ACTION.DOCUMENTS_SELF) } />
      )}
      {/* {false && <div onClick={() => {
      var element = document.createElement("a");
      var file = new Blob(
        [
          "https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg"
        ],
        { type: "image/*" }
      );
      element.href = URL.createObjectURL(file);
      element.download = "image.jpg";
      element.click();
    }}>
      <Icon name="download" />
    </div>} */}


    </div>
  );
};

export default ActionsCell;
