import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button as ButtonSemantic, Dropdown, DropdownProps, Loader, Modal } from "semantic-ui-react";
interface ModalFinalizeMeetingProps {
  modal: boolean;
  setModal: (T: boolean) => any;
  handleChangeReason: Function;
  handleSubmitReason: Function;
}

const ModalFinalizeMeeting: FC<ModalFinalizeMeetingProps> = ({
  modal,
  setModal,
  handleChangeReason,
  handleSubmitReason,
}: ModalFinalizeMeetingProps) => {
  const { t } = useTranslation();
  const optionsArray = [
    { key: "1", value: 1, text: t("home.endModal.status_reason.reason1"), code: "allRight" },
    { key: "2", value: 2, text: t("home.endModal.status_reason.reason2"), code: "absence" },
    { key: "3", value: 3, text: t("home.endModal.status_reason.reason3"), code: "skillsIssues" },
    { key: "4", value: 4, text: t("home.endModal.status_reason.reason4"), code: "technicalIssues" },
    { key: "5", value: 5, text: t("home.endModal.status_reason.reason7"), code: "other" },
  ];
  useEffect(() => {
    handleChangeReason(optionsArray[0].code);
  }, []);


  const handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const selectedOption = optionsArray.find(option => option.value === data.value);
    handleChangeReason(selectedOption!.code);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await handleSubmitReason();
    handleChangeReason(t("home.endModal.status_reason.reason1"));
    setModal(false);
    setLoading(false)
  };
  const [loading, setLoading] = useState(false);


  return (
    <>
      <style>{`
        .ui.dimmer .ui.loader:after {
          border-color: #808080 transparent transparent;  
        }
        .ui.dimmer .ui.loader {
          color: #808080;  
        }
        .ui.loader {
          margin-top: 50px;
        }
      `}</style>

      <Modal
        onOpen={() => setModal(true)}
        onClose={() => setModal(false)}
        open={modal}
        closeIcon
      >
        <Modal.Header>
          <h2>{t("home.endModal.header")}</h2>
        </Modal.Header>


        {loading ? (
          <Modal.Content>
            <Loader size="large" active={loading}>
              {t("loading.defect")}
            </Loader>
            <div className="margin-down" style={{ minHeight: '100px' }}></div>
          </Modal.Content>
        ) : (
          <Modal.Content>
            <h3>{t("home.endModal.subheader")}</h3>
            <Dropdown
              fluid
              selection
              inline
              options={optionsArray}
              defaultValue={1}
              onChange={handleChange}
            />
            <div className="margin-down" ></div>
          </Modal.Content>
        )}

        {!loading && (
          <Modal.Actions>
            <ButtonSemantic
              negative
              content={t("home.endModal.cancel")}
              onClick={() => setModal(false)}
            />
            <ButtonSemantic
              icon="checkmark"
              content={t("home.endModal.end")}
              positive
              onClick={handleSubmit}
            />
          </Modal.Actions>
        )}
      </Modal>
    </>
  );
}
  
export default ModalFinalizeMeeting;
