import React, { useContext, useState } from "react";
import { encode, decode } from "js-base64";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import { useForm } from "../../hooks/useForm";
import {
  defaultOrganitzationInfo,
  OrganitzationInfo,
} from "../../models/OrganitzationInfo";
import { Area } from "../../models/Area";
import { useLocation, useHistory } from "react-router-dom";
import {
  defaultPersonalization,
  deletePersonalitzationContent,
  fetchPersonalitzationContent,
  getAreaNotification,
  getOrganitzationNotification,
  getOrganization,
  getServiceNotification,
  Personalization,
  putOrganizationURL,
  putPersonalitzationContent,
  updateService,
  updateInformersName,
  updateType,
  putSmsLengthConsult,
  putServiceNotification,
} from "../../assets/personalitzation";
import { fetchAreaPublished } from "../../assets/areas";
import {
  getAdminOrganization,
  getServiceInfo,
  getAreaInfo,
  putArea,
  getServicePreferences,
  putServicePreferences,
} from "../../assets/api";
import Screen from "./Screen";
import { toast } from "react-toastify";
import { emailEventType } from "./types";

const ServiceSettings = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;

  const [emailEventFields, setEmailEventFields] = useState({
    emailEventConfirmationSubject: "",
    emailEventConfirmation: "",
    emailEventCancelation: "",
    emailWithLinktoEventSubject: "",
    emailWithLinktoEvent: "",
    emailWithLinktoEventExpressSubject: "",
    emailWithLinktoEventExpress: "",
    emailWithOTPSubject: "",
    emailWithOTPBody: "",
    smsWithOTPSubject: "",
    emailEventCancelationSubject: "",
    smsWithOTPBody: "",
    smsEventConfirmation: "",
    smsWithLinktoEvent: "",
    emailEventVideoReminderSubject: "",
    emailEventVideoReminder: "",
    smsVideoReminder: "",
    smsVideoReminderSubject: "",
    smsWithLinktoEventExpress: "",
  });
  const [organitzationURL, setOrganitzationURL] = useState<string>("");
  const [organitzationURLInformers, setOrganitzationURLInformers] =
    useState<string>("");
  const [loading, setLoading] = useState(false);
  const [organitzation, setOrganitzation] = useState<string>("");
  const [orgInfo, setOrgInfo] = useState<OrganitzationInfo>(
    defaultOrganitzationInfo
  );
  const history = useHistory();
  const [isRecording, setIsRecording] = useState(false);
  const [showName, setShowName] = useState(false); // para actualizar estado de mostrar o no el nombre de los informadores
  const [mailConfirmation, setMailConfirmation] = useState(false); //Envío de mail de confirmación desde la herramienta de videoatención
  const [smsConfirmation, setSmsConfirmation] = useState(false);
  const [mailLink, setMailLink] = useState(false);
  const [smsLink, setSmsLink] = useState(false);
  const [mailCancelation, setMailCancelation] = useState(false); //Envío de mail de cancelación desde la herramienta de videoatención
  const [mailReminder, setMailReminder] = useState(false);  // Iniciaran desactivados
  const [smsReminder, setSmsReminder] = useState(false); 
  const [selectedServiceType, setSelectedServiceType] = useState<string>("");
  const [areaNotification, setAreaNotification] = useState(false);
  const [areas, setAreas] = useState<Area[]>([]);
  const [area] = useState(props.match.params.areaCode);
  const [service] = useState(props.match.params.serviceCode);
  const [allowDocumentation, setAllowDocumentation] = useState(false);


  const [values, setValues, handleInputChange, reset] = useForm({
    entityOrganitzationURL: "",
    entityName: "",
    entityEmailConfirmationSubject: "",
    entityEmailConfirmationBody: "",
    entityEmailLinkSubject: "",
    entityEmailLinkBody: "",
    entitySmsConfirmationSubject: "",
    entitySmsConfirmationBody: "",
    entitySmsLinkSubject: "",
    entitySmsLinkBody: "",
    entitySmsLinkExpressSubject: "",
  });

  const onChangeFields = (x: emailEventType) => {
    setEmailEventFields({ ...emailEventFields, ...x });
  };

  function decodeMessages(emailEventFields: emailEventType) {
    setValues({
      entitySmsConfirmationSubject: decode(
        emailEventFields.smsEventConfirmationSubject
      ),
      entitySmsLinkSubject: decode(emailEventFields.smsWithLinktoEventSubject),
      entitySmsLinkExpressSubject: decode(emailEventFields.smsWithLinktoEventExpressSubject)
    });
    const decodedEventFields: any = Object.fromEntries(
      Object.entries(emailEventFields).map(([key, value]) => [
        key,
        decode(value),
      ])
    );
    onChangeFields(decodedEventFields);
  }

  const retrieveData = async (
    code: string,
    typeOfMeeting: string,
    channelType: string,
    token: string,
    area?: string,
    service?: string
  ) => {
    let data: Personalization = defaultPersonalization;
    try {
      const response: Response = await fetchPersonalitzationContent(
        code,
        typeOfMeeting,
        channelType,
        "ca",
        token,
        area,
        service
      );
      if (response.ok) {
        let aux = await response.json();
        if (aux.bodyData === null) aux.bodyData = "";
        if (aux.subjectData === null) aux.subjectData = "";
        data = aux;
      }
    } catch (error) {
      console.warn(error);
    }
    return data;
  };


  const updateRecordingsForOrganization = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    await updateService(service, !isRecording, backoffice)
      .then((response) => response.json())
      .then((response) => {
        setIsRecording(!isRecording);

          const { areaCode } = response;
          dispatch(
            fetchAreaPublished(
              areaCode,
              backoffice,
              t("area.publish.success"),
              t("area.publish.error")
            )
          );
      });
  };

  const initArea = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    try {
      return await getServiceInfo(service, backoffice)
        .then((response) => {
          if (!response.ok)
            throw new Error(t("edit.service.consult_error"));

          return response.json();
        })
        .then(async (response) => {
          console.log("REPS", response.showName);
          const resp1 = response;
          resp1.showName = !showName;
          console.log("resp1", resp1);
          return resp1;
        });
    } catch (er) {
      toast((er.message), { type: "error"});
    }
    setLoading(false);
  };

  const initSelected = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    try {
      return await getServiceInfo(service, backoffice)
        .then((response) => {
          if (!response.ok)
            throw new Error(t("edit.service.consult_error"));

          return response.json();
        })
        .then(async (response) => {
          console.log("REPS", response.selectedServiceType);
          const resp1 = response;
          if (resp1.selectedServiceType === 'C') resp1.selectedServiceType = 'T';
          else resp1.selectedServiceType = 'C';
          console.log("resp1", resp1);
          return resp1;
        });
    } catch (er) {
      toast((er.message), { type: "error"});
    }
    setLoading(false);
  };

  const updateInformersNamesForService = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    const result =  await initArea();

    await updateInformersName(service, result, backoffice)
      .then((response) => response.json())
      .then((response) => {
        setShowName(!showName);

        /*
        const { areaCode } = response;
          dispatch(
            fetchAreaPublished(
              areaCode,
              backoffice,
              t("area.publish.success"),
              t("area.publish.error")
            )
          );*/
      })
  }

  
  const updateType = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    const result =  await initSelected();

    await updateInformersName(service, result, backoffice)
      .then((response) => response.json())
      .then((response) => {
        if(selectedServiceType === 'C') setSelectedServiceType('T');
        else if (selectedServiceType === 'T') setSelectedServiceType('C')

        /*
        const { areaCode } = response;
          dispatch(
            fetchAreaPublished(
              areaCode,
              backoffice,
              t("area.publish.success"),
              t("area.publish.error")
            )
          );*/
      })
  };



  const updateEmailCancelled = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailEventCancelation.length === 0 && emailEventFields.emailEventCancelationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingCancelled", "Mail", "ca", backoffice, "both", area, service);
    }
    else if(emailEventFields.emailEventCancelation.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingCancelled", "Mail", "ca", backoffice, "body", area, service);
    }
    else if(emailEventFields.emailEventCancelationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingCancelled", "Mail", "ca", backoffice, "subject", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailEventCancelationSubject),
        bodyData: encode(emailEventFields.emailEventCancelation),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingCancelled",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  };

  const updateServiceNotification = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    await  putServiceNotification(
      service,
      mailConfirmation,
      mailLink,
      mailCancelation,
      smsConfirmation,
      smsLink,
      mailReminder,
      smsReminder,
      backoffice
    ).then((response) => {
      console.log(response);
    });
  }

  const updateAllowDocumentation = async () => {
    setAllowDocumentation(!allowDocumentation);
    if (allowDocumentation) {
      toast.info(t("detail.personalitzation.sending_previous_notification"));
    }
  }

  const updateMailConfirmation = async () => {
    setMailConfirmation(!mailConfirmation);
  }

  const updateMailCancelation = async () => {
    setMailCancelation(!mailCancelation);
  }

  const updateMailReminder = async () => {
    setMailReminder(!mailReminder);
  }

  const updateSmsReminder = async () => {
    setSmsReminder(!smsReminder);
  }

  const updateMailLink = async () => {
    setMailLink(!mailLink);
  }

  const updateControlSmsConfirmation = async () => {
    setSmsConfirmation(!smsConfirmation);
  }

  const updateControlSmsLink = async () => {
    setSmsLink(!smsLink);
  }


  async function handleSubmit() {
    setLoading(true);
    try {
      const body = {
        servant: organitzationURLInformers,
        citizen: organitzationURL,
        allowDocumentation: allowDocumentation
      };
      const response = await putServicePreferences(service, body, token);
      if (!response.ok) throw new Error("response bad");
    } catch (error) {
      setLoading(false);
      console.warn(error);
      toast(t("detail.personalitzation.error.email_confirmation"), { type: "error"});
    }
    try {
      updateEmailConfirmation();
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.email_confirmation"), { type: "error"});
    }
    try {
      await updateEmailLinkExpress().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.email_link_express"), { type: "error"});
    }
    try {
      await updateEmailLink().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.email_link"), { type: "error"});
    }

    try {
      await updateSmsConfirmation().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.sms_confirmation"), { type: "error"});
    }

    try {
      await consultSmsConfirmationLength().then((response) => {
        console.log(response);
        if (!response) toast(t("detail.personalitzation.error.sms_length_confirmation"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }
    try {
      await updateSmsLinkExpress().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.sms_link_express"), { type: "error"});
    }
    try {
      await updateSmsLink().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.sms_link"), { type: "error"});
    }
    try {
      await consultSmsLinkExpressLength().then((response) => {
        console.log(response);
        if (!response) toast(t("detail.personalitzation.error.sms_length_link_express"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }
    try {
      await consultSmsLinkLength().then((response) => {
        console.log(response);
        if (!response) toast(t("detail.personalitzation.error.sms_length_link"), {type: "error"});
      });
    } catch (error) {
 
      console.error(error);
    }
    try {
      await updateSmsOTP().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.sms_link"), { type: "error"});
    }
    try {
      await consultSmsOTPLength().then((response) => {
        if (!response) toast(t("detail.personalitzation.error.sms_length_otp"), {type: "error"});
      });
    } catch (error) {
        console.error(error);
    }
    try {
      await isSmsTooLong().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.sms_too_long"), {type: "error"});
    }
    try {
      await updateEmailOTP().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.mail_cancelation"), { type: "error"});
    }
    try {
      await updateEmailVideoReminder().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.email_videoReminder"), { type: "error"});
    }

    try {
      await updateSmsVideoReminder().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.sms_length_videoReminder"), { type: "error"});
    }

    try {
      await consultSmsVideoreminder().then((response) => {
        console.log(response);
        if (!response) toast(t("detail.personalitzation.error.sms_length_videoReminder"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }

    try {
      await updateEmailCancelled().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("detail.personalitzation.error.sms_link"), { type: "error"});
    }

    try {
      await updateServiceNotification().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
    }
    

    setLoading(false);
    toast(t("detail.personalitzation.confirm"), { type: "success"});
  }

  async function updateEmailConfirmation() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailEventConfirmation.length === 0 && emailEventFields.emailEventConfirmationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Mail", "ca", backoffice, "both", area, service);
    }
    else if(emailEventFields.emailEventConfirmation.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Mail", "ca", backoffice, "body", area, service);
    }
    else if(emailEventFields.emailEventConfirmationSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Mail", "ca", backoffice, "subject", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailEventConfirmationSubject),
        bodyData: encode(emailEventFields.emailEventConfirmation),
      };
      await putPersonalitzationContent(
        organitzation,
        "CreateMeeting",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateEmailLinkExpress() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailWithLinktoEventExpress.length === 0 && emailEventFields.emailWithLinktoEventExpressSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "ExpressMeetingReminder", "Mail", "ca", backoffice, "both", area, service);
    }
    else if(emailEventFields.emailWithLinktoEventExpress.length === 0){
      await deletePersonalitzationContent(organitzation, "ExpressMeetingReminder", "Mail", "ca", backoffice, "body", area, service);
    }
    else if(emailEventFields.emailWithLinktoEventExpressSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "ExpressMeetingReminder", "Mail", "ca", backoffice, "subject", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithLinktoEventExpressSubject),
        bodyData: encode(emailEventFields.emailWithLinktoEventExpress),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "ExpressMeetingReminder",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateEmailLink() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailWithLinktoEvent.length === 0 && emailEventFields.emailWithLinktoEventSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "both", area, service);
    }
    else if(emailEventFields.emailWithLinktoEvent.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "body", area, service);
    }
    else if(emailEventFields.emailWithLinktoEventSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "subject", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithLinktoEventSubject),
        bodyData: encode(emailEventFields.emailWithLinktoEvent),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateSmsConfirmation() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsEventConfirmation.length === 0){
      await deletePersonalitzationContent(organitzation, "CreateMeeting", "Sms", "ca", backoffice, "body", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsEventConfirmation),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "CreateMeeting",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsConfirmationLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsEventConfirmation.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsEventConfirmation),
      };
      await putSmsLengthConsult(
        organitzation,
        "CreateMeeting",
        "Sms",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
        ).then((response) => {
          console.log(response);
          resp = response;
          return response;
        })
    }
    return resp;
  }

  async function updateSmsLinkExpress() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsWithLinktoEventExpress.length === 0){
      await deletePersonalitzationContent(organitzation, "ExpressMeetingReminder", "Sms", "ca", backoffice, "body", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEventExpress),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "ExpressMeetingReminder",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateSmsLink() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsWithLinktoEvent.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Sms", "ca", backoffice, "body", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEvent),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsLinkExpressLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsWithLinktoEventExpress.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEventExpress),
      };
      await putSmsLengthConsult(
        organitzation,
        "ExpressMeetingReminder",
        "Sms",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function consultSmsLinkLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsWithLinktoEvent.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEvent),
      };
      await putSmsLengthConsult(
        organitzation,
        "MeetingReminder",
        "Sms",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function updateSmsOTP() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsWithOTPBody.length === 0){
      await deletePersonalitzationContent(organitzation, "SendOtp", "Sms", "ca", backoffice, "body", area, service);
    }else{
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithOTPSubject),
        bodyData: encode(emailEventFields.smsWithOTPBody),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "SendOtp",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateEmailVideoReminder() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailEventVideoReminderSubject.length === 0 && emailEventFields.emailEventVideoReminder.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder24", "Mail", "ca", backoffice, "both", area, service);
    } 
    else if(emailEventFields.emailEventVideoReminder.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "body", area, service);
    }
    else if(emailEventFields.emailEventVideoReminderSubject.length === 0){
      await deletePersonalitzationContent(organitzation, "MeetingReminder", "Mail", "ca", backoffice, "subject", area, service);
    } 
    else {
      const bodyEmailEventVideoReminder = {
        subjectData: encode(emailEventFields.emailEventVideoReminderSubject),
        bodyData: encode(emailEventFields.emailEventVideoReminder),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder24",
        "Mail",
        "ca",
        bodyEmailEventVideoReminder,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }
  }

  async function updateSmsVideoReminder() {
    const { backoffice } = await authContext.getTokenForScopes();
    if(emailEventFields.smsVideoReminder.length === 0){
    await deletePersonalitzationContent(organitzation, "MeetingReminder24", "Sms", "ca", backoffice, "body", area, service);
  } else {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsVideoReminderSubject),
        bodyData: encode(emailEventFields.smsVideoReminder),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder24",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });

    }

  }

  async function consultSmsVideoreminder() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if (emailEventFields.smsVideoReminder.length !== 0) {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsVideoReminderSubject),
        bodyData: encode(emailEventFields.smsVideoReminder),
      };
      await putSmsLengthConsult(
        organitzation,
        "MeetingReminder24",
        "Sms",
        bodyEmailConfirmation,
        backoffice
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function consultSmsOTPLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if (emailEventFields.smsWithOTPBody.length !== 0) {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithOTPSubject),
        bodyData: encode(emailEventFields.smsWithOTPBody),
      };
      await putSmsLengthConsult(
        organitzation,
        "SendOtp",
        "Sms",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function isSmsTooLong() {
    const { backoffice } = await authContext.getTokenForScopes();
  }

  async function updateEmailOTP() {
    const { backoffice } = await authContext.getTokenForScopes();

    console.log("Update Email OTP", emailEventFields.emailWithOTPSubject, emailEventFields.emailWithOTPBody)

    if(emailEventFields.emailWithOTPBody.length === 0){
      await deletePersonalitzationContent(organitzation, "SendOtp", "Mail", "ca", backoffice, "body", area, service);
    } else {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithOTPSubject),
        bodyData: encode(emailEventFields.emailWithOTPBody),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "SendOtp",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice,
        area,
        service
      ).then((response) => {
        console.log(response);
      });
    }

  }

  const historyHandleClick = () => {
    history.goBack();
  };

  const init = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    setLoading(true);
    const typeOfMeetings: string[] = [
      "CreateMeeting",
      "CreateExpressMeeting",
      "ExpressMeetingReminder",
      "MeetingReminder",
      "MeetingCancelled",
      "SendOtp",
      "MeetingReminder24",
    ];

    const channelType: string[] = ["Mail", "Sms"];
    await getAdminOrganization(backoffice)
      .then(async (response: any) => {
        if (response.ok) return await response.json();
        throw new Error("Error");
      })
      .then(async (response: any) => {
        const code = response.code;
        setOrganitzation(code);

        let urlBody: any = await getServicePreferences(service, backoffice).then(
          (response) => response.json()
        );
        setOrganitzationURL(urlBody.citizen);
        setOrganitzationURLInformers(urlBody.servant);
        let infoAboutOrganization: OrganitzationInfo = await getServiceInfo(
          service,
          backoffice
        ).then((response) => response.json());
        setOrgInfo(infoAboutOrganization);
        setIsRecording(infoAboutOrganization.recordMeetingEnabled);
        setShowName(infoAboutOrganization.showName);
        setAreas(infoAboutOrganization.areas);
        setAllowDocumentation(infoAboutOrganization.sendPreviousDocumentation);
        setSelectedServiceType(infoAboutOrganization.selectedServiceType);

        const {
          bodyData: emailEventConfirmation,
          subjectData: emailEventConfirmationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[0],
          channelType[0],
          backoffice,
          area,
          service
        );
        const {
          bodyData: smsEventConfirmation,
          subjectData: smsEventConfirmationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[0],
          channelType[1],
          backoffice,
          area,
          service
        );
        const {
          bodyData: emailWithLinktoEventExpress,
          subjectData: emailWithLinktoEventExpressSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[2],
          channelType[0],
          backoffice,
          area,
          service
        );
        const {
          bodyData: emailWithLinktoEvent,
          subjectData: emailWithLinktoEventSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[3],
          channelType[0],
          backoffice,
          area,
          service
        );
        const {
          bodyData: smsWithLinktoEventExpress,
          subjectData: smsWithLinktoEventExpressSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[2],
          channelType[1],
          backoffice,
          area,
          service
        );
        const {
          bodyData: smsWithLinktoEvent,
          subjectData: smsWithLinktoEventSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[3],
          channelType[1],
          backoffice,
          area,
          service
        );
        const {
          bodyData: emailEventCancelation,
          subjectData: emailEventCancelationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[4],
          channelType[0],
          backoffice,
          area,
          service
        );
        const { bodyData: emailWithOTPBody, subjectData: emailWithOTPSubject } =
          await retrieveData(
            code,
            typeOfMeetings[5],
            channelType[0],
            backoffice,
            area,
            service
          );
        const { bodyData: smsWithOTPBody, subjectData: smsWithOTPSubject } =
          await retrieveData(
            code,
            typeOfMeetings[5],
            channelType[1],
            backoffice,
            area,
            service
          );
          const { bodyData: emailEventVideoReminder, subjectData: emailEventVideoReminderSubject } =
          await retrieveData(
            code,
            typeOfMeetings[6],
            channelType[0],
            backoffice,
            area,
            service
          );
          const { bodyData: smsVideoReminder, subjectData: smsVideoReminderSubject } =
          await retrieveData(
            code,
            typeOfMeetings[6],
            channelType[1],
            backoffice,
            area,
            service
          );
        console.log({
          smsEventConfirmationSubject,
          smsWithLinktoEventSubject,
          emailEventConfirmationSubject,
          emailEventConfirmation,
          emailEventCancelationSubject,
          emailEventCancelation,
          emailWithLinktoEventSubject,
          emailWithLinktoEvent,
          emailWithOTPSubject,
          smsWithOTPSubject,
          emailWithOTPBody,
          smsWithOTPBody,
          smsEventConfirmation,
          smsWithLinktoEvent,
          smsVideoReminderSubject,
        });
        decodeMessages({
          smsEventConfirmationSubject,
          smsWithLinktoEventSubject,
          smsWithLinktoEventExpressSubject,
          emailEventConfirmationSubject,
          emailEventConfirmation,
          emailEventCancelationSubject,
          emailEventCancelation,
          emailWithLinktoEventExpressSubject,
          emailWithLinktoEventExpress,
          emailWithLinktoEventSubject,
          emailWithLinktoEvent,
          emailWithOTPSubject,
          smsWithOTPSubject,
          emailWithOTPBody,
          smsWithOTPBody,
          smsEventConfirmation,
          smsWithLinktoEvent,
          emailEventVideoReminderSubject,
          emailEventVideoReminder,
          smsVideoReminder,
          smsVideoReminderSubject,
          smsWithLinktoEventExpress
        });

        await getOrganitzationNotification(code, backoffice)
      .then(async (response: any) => {
        if (response.ok) return await response.json();
        throw new Error("Error");
      })
      .then(async (response: any) => {
        if (response.externalNotification) {
          await getAreaNotification(area, backoffice)
          .then(async (response: any) => {
            if (response.ok) return await response.json();
            throw new Error("Error");
          })
          .then(async (response: any) => {
            setAreaNotification(response.externalNotification);
          });
        }

        else setAreaNotification(false);
      });

    });

    await getServiceNotification(service, token)
    .then(async (response: any) => {
      if (response.ok) return await response.json();
      throw new Error("Error");
    })
    .then(async (response: any) => {
      console.log("Service Notification response:", response);
      setMailConfirmation(response.emailConfirmacion);
      setMailLink(response.emailEnlace);
      setMailCancelation(response.emailCancelacion);
      setSmsConfirmation(response.smsConfirmacion);
      setSmsLink(response.smsEnlace);
      setMailReminder(response.mailReminder);
      setMailReminder(response.emailReminder);
      setSmsReminder(response.smsReminder);
    });

    await getServicePreferences(service, token)
    .then(async (response: any) => {
      if (response.ok) return await response.json();
      throw new Error("Error");
    })
    .then(async (response: any) => {
      console.log("Service Preferences response:", response);
      setAllowDocumentation(response.allowDocumentation);
    });


    setLoading(false);
  };

  return (
    <Screen
      {...props}
      emailEventFields={emailEventFields}
      onChangeFields={onChangeFields}
      decodeMessages={decodeMessages}
      init={init}
      loading={loading}
      values={values}
      organitzationURL={organitzationURL}
      isRecording={isRecording}
      selectedServiceType={selectedServiceType}
      setOrganitzationURL={setOrganitzationURL}
      organitzationURLInformers={organitzationURLInformers}
      setOrganitzationURLInformers={setOrganitzationURLInformers}
      updateRecordingsForOrganization={updateRecordingsForOrganization}
      historyHandleClick={historyHandleClick}
      handleSubmit={handleSubmit}
      updateInformersNamesForService={updateInformersNamesForService}
      updateType={updateType}
      showName={showName}
      mailConfirmation={mailConfirmation}
      updateMailConfirmation={updateMailConfirmation}
      smsConfirmation={smsConfirmation}
      updateSmsConfirmation={updateControlSmsConfirmation}
      mailLink={mailLink}
      updateMailLink={updateMailLink}
      smsLink={smsLink}
      updateSmsLink={updateControlSmsLink}
      mailCancelation={mailCancelation}
      updateMailCancelation={updateMailCancelation}
      mailReminder={mailReminder}
      updateMailReminder={updateMailReminder}
      smsReminder={smsReminder}
      updateSmsReminder={updateSmsReminder}
      areaNotification={areaNotification}
      allowDocumentation={allowDocumentation}
      updateAllowDocumentation={updateAllowDocumentation}
    />
  );
};

export default ServiceSettings;