import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { OrganitzationInfo } from "../models/OrganitzationInfo";
import { fetchMSGraph } from "../util/auth";

export type Personalization = {
  organizationCode: string;
  eventType: string;
  channelType: string;
  culture: string;
  subjectData: string;
  bodyData: string;
};

export const defaultPersonalization = {
  organizationCode: "",
  eventType: "",
  channelType: "",
  culture: "",
  subjectData: "",
  bodyData: "",
};

export async function fetchPersonalitzationContent(
  organitzationCode: string,
  eventType: string,
  channelType: string,
  culture: string,
  token: string,
  area?: string,
  service?: string
) {
  const endpoint = `${
    process.env.REACT_APP_API_URL
  }/Personalization/${organitzationCode}/${area ? area + "/" : ""}${
    service ? service + "/" : ""
  }${eventType}/${channelType}/${culture}`;
  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function getOrganization(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${code}`;
  const response = await fetchMSGraph(endpoint, token);

  return response;
}
export async function getOrganizations(userCode: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/OrganizationsUsers/${userCode}`;
  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function getAreasFromOrganization(code: string, token: string){
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${code}/areas`;
  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function getOrganizationURL(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${code}/url`;
  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function getOrganizationAdministrators(
  code: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${code}/administrators`;
  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function putOrganizationURL(
  codeOrg: string,
  url: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${codeOrg}/url`;
  const body = JSON.stringify({
    ...url,
    code: codeOrg,
  });
  const response: Response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );
  return response;
}

export async function updateOrganitzation(
  code: string,
  status: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${code}/recording/${status}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT);
  return response;
}

export async function updateArea(
  code: string,
  status: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${code}/recording/${status}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT);
  return response;
}

export async function updateService(
  code: string,
  status: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${code}/recording/${status}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT);
  return response;
}

export async function updateInformersName(
  code: string,
  body: any,
  token: string
) {
  //pendiente: modificar endpoint con la llamada al metodo correcto.
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${code}`;
  const bodyStringify = JSON.stringify(body);
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT, bodyStringify);
  return response;
}

export async function updateType(
  code: string,
  status: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${code}/recording/${status}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT);
  return response;
}

export async function updateInformersNameArea(
  code: string,
  token: string
) {
  //pendiente: modificar endpoint con la llamada al metodo correcto.
  const endpoint = `${process.env.REACT_APP_API_URL}/Area/${code}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT)
  return response;
}

export async function putPersonalitzationContent(
  organitzationCode: string,
  eventType: string,
  channelType: string,
  culture: string,
  newMessage: {},
  token: string,
  area?: string,
  service?: string
) {
  const endpoint = `${
    process.env.REACT_APP_API_URL
  }/Personalization/${organitzationCode}/${area ? area + "/" : ""}${
    service ? service + "/" : ""
  }${eventType}/${channelType}/${culture}`;

  const body = JSON.stringify(newMessage);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );

  return response;
}

export async function putSmsLengthConsult(
  organitzationCode: string,
  eventType: string,
  channelType: string,
  newMessage: {},
  token: string,
  area?: string,
  service?: string
) {
  const endpoint = `${
    process.env.REACT_APP_API_URL
  }/Personalization/simulator/risk/${organitzationCode}/${area ? area + "/" : ""}${
    service ? service + "/" : ""
  }${eventType}/${channelType}/ca`;

  const body = JSON.stringify(newMessage);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );

  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse;
  } else {
    throw new Error('Request failed');
  }
}

export async function putServiceNameLength(
  organitzationCode: string,
  areaCode: string,
  serviceCode: string,
  culture: string,
  newMessage: {},
  token: string,
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/simulator/${organitzationCode}/${areaCode}/${serviceCode}/${culture}`

  const body = JSON.stringify(newMessage);

  const response  = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );
  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse;
  } else {
    throw new Error('Request failed');
  }

}

export async function deletePersonalitzationContent(
  organitzationCode: string,
  eventType: string,
  channelType: string,
  culture: string,
  token: string,
  element: string,
  area?: string,
  service?: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/${organitzationCode}/${area ? area + "/" : ""}${
    service ? service + "/" : ""
  }${eventType}/${channelType}/${culture}/${element}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}

export async function postPersonalitzationContent(
  newMessage: string,
  organitzationCode: string,
  eventType: string,
  channelType: string,
  culture: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/${organitzationCode}/${eventType}/${channelType}/${culture}`;

  const body = JSON.stringify(newMessage);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function postOrganitzationLogo(
  image: string,
  organitzationCode: string,
  eventType: string,
  channelType: string,
  culture: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalitzation/media/${organitzationCode}`;

  const body = JSON.stringify(image);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function getServiceNotification(
  serviceCode: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/service_notification/${serviceCode}`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function putServiceNotification(
  serviceCode: string,
  emailConfirmacion: boolean,
  emailEnlace: boolean,
  emailCancelacion: boolean,
  smsConfirmacion: boolean,
  smsEnlace: boolean,
  emailReminder: boolean,
  smsReminder: Boolean,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/notifications_service/${serviceCode}`;

  const body = JSON.stringify({
    emailConfirmacion: emailConfirmacion,
    emailEnlace: emailEnlace,
    emailCancelacion: emailCancelacion,
    smsConfirmacion: smsConfirmacion,
    smsEnlace: smsEnlace,
    emailReminder: emailReminder,
    smsReminder: smsReminder
  });

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );

  return response;
}

export async function getAreaNotification(
  areaCode: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/area_notification/${areaCode}`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function putAreaNotification (
  areaCode: string,
  externalNotification: boolean,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/notifications_area/${areaCode}`;

  const body = JSON.stringify({externalNotification: externalNotification});
  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );

  return response;
}

export async function getOrganitzationNotification(
  organizationCode: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/organization_notification/${organizationCode}`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function putOrganitzationNotification (
  organizationCode: string,
  externalNotification: boolean,
  reminder: boolean,
  hoursForReminder: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Personalization/notifications_organization/${organizationCode}`;

    const body = JSON.stringify({externalNotification: externalNotification, reminder: reminder, hoursForReminder: parseInt(hoursForReminder)});
    const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );

  return response;
}