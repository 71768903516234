import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Table } from "semantic-ui-react";
import { renderList, UserService } from "../Dashboard";
import { TableCellName } from "./TableCellName";
import { LayoutContext } from "../../../context/LayoutContext";

interface Props {
  services: renderList[];
  serviceSelected: Function;
  isLoading: boolean;
}

export const ServiceList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const { setTitle, setBreadcrumb } = layoutContext;


  function selectEvent(service: UserService) {
    props.serviceSelected(service);
  }

  return (
    <>
      {!props.isLoading ? (
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("home.list.service_name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("home.list.queue_status")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.services.map((service) => (
              <Table.Row
                style={{ cursor: "pointer" }}
                key={service.service.code}
                onClick={() => selectEvent(service.service)}
              >
                <TableCellName codeService={service.service.code} />
                <Table.Cell>{service.count}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Loader active inline="centered" content={t("loading.defect")} size="medium" />
        // <Loader active inline="centered" content="Loading" size="medium">
        //   {t("loading.defect")}
        // </Loader>

      )}
    </>
  );
};
