export const statusOtp = (status: boolean) => {
    let response = "";
    switch (status) {
      case true:
        console.log("TRUE");
        response = "detail.otp.available";
        break;
      case false:
        console.log("FALSE");
        response = "detail.otp.available";
        break;
      default:
        response = status;
        break;
    }
    return response;
  };