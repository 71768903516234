
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getUserServices } from "../../../../assets/api";
import { AuthContext } from "../../../../context/AuthContext";
import { AuthorizationModel } from "../../../../models/Authorization";
import { UserService } from "../../Dashboard";
import Screen from "./Screen";
import { Loader } from "semantic-ui-react";

interface Props {
  meetings: any[];
  contentMeeting?: (string | any)[];
  servicesRefresh?: () => void;
  onClickMeeting?: (x: any) => void;
  disabledMeeting?: (x: any) => boolean;
  updateMeetingAssigned: (x: any) => void;
  loading?: boolean;
}

const AssignedMeetings =(props: Props) => {
  
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  
  const meetings = props.meetings.map((_) => {
    _["start"] = _["meetingStart"];
    _["code"] = _["meetingID"];
        return _;
  });


    const updateMeetingAssigned = async () => {

        const { backoffice, shared } = await authContext.getTokenForScopes();
        const account: AuthorizationModel = await authContext
          .getAccount()
          .then((response) => JSON.parse(response));
        const name = account.profile.code;
    
        const responseServices: Response = await getUserServices(name, backoffice);
        if (!responseServices.ok) {
          throw new Error(t("home.error.userServices"))};
        const dataServices: UserService[] = await responseServices.json();
        props.updateMeetingAssigned(dataServices);
        
    
       };

    
    
      useEffect(() => {
            updateMeetingAssigned();
          }, [])




  return props.loading ? (
    <div className="mb-4 flex items-center justify-center" style={{ minHeight: '150px', position: 'relative' }}>
      <h2>{t("home.assigned_meetings")}</h2>
      <Loader size="large" active style={{ marginTop: '50px' }}>
        {t("loading.defect")}
      </Loader>
    </div>
  ) : (
    <>
    <h2>{t("home.assigned_meetings")}</h2>
    <Screen {...props} meetings={meetings}  servicesRefresh={updateMeetingAssigned} />
    </>
  );
};

export default AssignedMeetings;




