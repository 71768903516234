import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../context/LayoutContext";
import {
  fetchAreaPublished,
  fetchGetAreasServicesApi,
  fecthMyAreas,
  cleanAreasApi,
  selectServices,
  selectMyAreas,
} from "../../assets/areas";
import { countMeetings, selectMeetingsCount } from "../../assets/meetings";
import Layout from "../Layout/LayoutContainer";
import {
  Dropdown,
  DropdownProps,
  Button as SemanticButton,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Button } from "../Button/Button";
import { Area } from "../../models/Area";
import Card from "../Card/Card";
import ListService from "./ListService/ListService";
import { AuthContext } from "../../context/AuthContext";
import { getAreaInfo, getService } from "../../assets/api";
import { getNameAreaLanguage } from "../../util/languages";
import { AreaInfo } from "../../models/AreaInfo";
import { toast } from "react-toastify";
import { ServiceInfo } from "../../models/ServiceInfo";
import { Service } from "../../types/area";



type areaLang = {
  displayName: string;
  code: string;
  services?: Service[]
};



const DashboardArea: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const [token, setToken] = useState("");

  useEffect(() => {
    getToken();
  }, []);

  const { setTitle, setBreadcrumb } = layoutContext;
  const dispatch = useDispatch();
  const entitiesOptions = useSelector(selectServices);
  const countAPI = useSelector(selectMeetingsCount);
  const areas = useSelector(selectMyAreas);
  const [areaSelect, setAreaSelect] = useState("");
  const [arrayAreas, setArrayAreas] = useState<areaLang[]>([]);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [isShowButton, setIsShowButton] = useState<boolean>(true);
  const [arrayServices, setArrayServices] = useState<Service[]>([])

  
  

  useEffect(() => {
    setTitle(t("area.title"));
    setBreadcrumb([
      {
        name: t("area.title"),
      },
    ]);

    (async () => {
      await dispatch(fecthMyAreas(await getToken()));
    })();
    return () => {
      dispatch(cleanAreasApi());
    };
  }, [t, setTitle, setBreadcrumb]);

  useEffect(() => {
    (async () => {
      if (areaSelect !== "") {
        await dispatch(fetchGetAreasServicesApi(areaSelect, await getToken()));
      }
    })();
  }, [areaSelect, token]);

  const HandleAvailability = () => {
    const history = useHistory();
    const [areaCode] = useState(props.params.areaCode);
    const [serviceCode] = useState(props.params.serviceCode);
    return history.push(`/area/${areaCode}/services/${serviceCode}/disponibilitat`);
  };

  const HandleSettingsService = () => {
    history.push(`/area/${props.params.areaCode}/services/${props.params.serviceCode}/settings`);
  };

  const getToken = async () => {
    if (token) return token;
    else {
      const newToken = await (await authContext.getTokenForScopes()).backoffice;
      setToken(newToken);
      return newToken;
    }
  };
  


  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const aux: areaLang[] = [];
        for (let area of areas) {
          const res = await getAreaInfo(area.code, await getToken());
          if (res.ok) {
            const areaInfo: AreaInfo = await res.json();
            const l = getNameAreaLanguage(areaInfo);
            aux.push({ code: areaInfo.code, displayName: l.displayName.toUpperCase()});
            
            // aux.push({ code: areaInfo.code, displayName: l.displayName.toUpperCase(),  services: areaInfo.services});
            
          } else {
            throw new Error(t(`area.message.info.error`) + ` (${area.code})`);
          }
        }
        setArrayAreas(aux);
      } catch (error) {
        toast(error.message, { type: "error" });
      }
    };

    fetchAreas();
  }, [areas, t]);

  useEffect(() => {
    if (areaSelect !== "") {
      setIsShowButton(false);
    }
  }, [areaSelect]);

  useEffect(() => {
    if (arrayAreas.length > 0 && !areaSelect) {
      const options = optionsAreas();
      if (options.length > 0) {
        setAreaSelect(options[0].value);
      }
    }
  }, [arrayAreas, areaSelect]);

  useEffect(() => {
    (async () => {
      await dispatch(
        countMeetings(areaSelect, entitiesOptions, await getToken())
      );
      await setIsLoadingServices(false);
    })();
  }, [areaSelect, entitiesOptions]);

  const publishArea = async () => {
    dispatch(
      fetchAreaPublished(
        areaSelect,
        token,
        t("area.publish.success"),
        t("area.publish.error")
      )
    );
  
    await servicesRefresh();
  };

  const handleHistoryClick = () => {
    history.push("/services/create");
  };

  const handleAreaSettingsClick = () => {
    history.push(`/area/${areaSelect}/settings`);
  };

  const handleAreaRecordingClick = () => {
    history.push(`/area/${areaSelect}/recording`);
  };

  const handleSearchClick = () => {
    history.push("/area/search");
  };

  useEffect(() => {
    const area = props.location?.state?.area;
    if (area) {
      setAreaSelect(area);
    }
  }, [props.location?.state]);

  const onChangeArea = async (event: any, data: DropdownProps) => {
    if (typeof data.value === "string") {
      setAreaSelect(data.value);
    }
  };

  const compare = (a: any, b: any) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  };

  const optionsAreas = () => {
    let arrayOption: any[] = [];
    arrayAreas.map((area, index) => {
      let a = { key: index, value: area.code, text: area.displayName };
      arrayOption.push(a);
    });

    return arrayOption.sort(compare);
  };

  const servicesRefresh = async () => {
    await setIsLoadingServices(true);
    await setTimeout(async () => {
      await dispatch(fetchGetAreasServicesApi(areaSelect, await getToken()));
    }, 2000);
  };


  const arrayButtonsAreaUnselected = [
    {
      icon: "search",
      message: "area.meeting_search",
      onClick: handleSearchClick,
    },
  ];

  const arrayButtonsArea = [
    {
      icon: "setting",
      message: "area.area_settings",
      onClick: handleAreaSettingsClick,
    },
    {
      icon: "video",
      message: "area.btn_recordings",
      onClick: handleAreaRecordingClick,
    },
    {
      icon: "search",
      message: "area.meeting_search",
      onClick: handleSearchClick,
    },
    {
      icon: "sync",
      message: "area.area_publish",
      onClick: publishArea,
    },
    {
      icon: "plus",
      message: "area.service_btn",
      onClick: handleHistoryClick,
    },
  ];

  return (
    <>

      <Layout.Main>
        <h2>{t("area.service_select")}</h2>
        <Dropdown
          className={"dropdown-area mb-4 br04 w-100"}
          selection
          options={optionsAreas()}
          onChange={onChangeArea}
          value={areaSelect}
        />

        {entitiesOptions.length > 0 && (
          <Card>
            <Card.Body>
              <div className={"d-flex"}>
                <SemanticButton
                  negative
                  content={t("refresh")}
                  icon={"refresh"}
                  onClick={servicesRefresh}
                />
              </div>
              {isLoadingServices ? (
                <Segment padded={"very"} size={"big"}>
                  <Dimmer active inverted>
                    <Loader size="medium">{t("loading.defect")}</Loader>
                  </Dimmer>
                </Segment>
              ) : (
                areaSelect && (
                  <ListService
                    arrayService={entitiesOptions}
                    areaCode={areaSelect}
                    countServiceMeeting={countAPI}
                  />
                )
              )}
            </Card.Body>
          </Card>
        )}
      </Layout.Main>
      <Layout.Article>
        {(isShowButton
          ? arrayButtonsAreaUnselected
          : arrayButtonsArea
        ).map((button) => (
          <Button
            message={button.message}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </Layout.Article>
    </>
  );
};

export default DashboardArea;
// useEffect(() => {
  // const arrayButtonsArea = [
  //   {
  //     icon: "search",
  //     message: "area.meeting_search",
  //     onClick: handleSearchClick,
  //   }]);