import { assignMeeting, deleteMeetingBySlot, discardMeeting, downloadDocumentBySlot, filterMeetingsPerServiceAndDate, getMeetingsQueueStatus } from "../../../services/events.service";
import { deleteUserFromArea, getPresence, getUsersFromAreaCode, getUsersFromServiceCode, putUsersService } from "../../../services/services.service";
import { createEventDetailAdapter, createUsersAdapter, error, info } from "../../../adapters";

import { IEventDetailAdapter, IEventDetailResponse } from "../../../models/event.models";
import { IUsersResponse, IUsersAdapter, IPresenceResponse } from "../../../models/user.models";
import axios from "axios";

export const getMeetingsFromDate = async (start: string, end: string, area: string, serviceCode: string, token: string, dispatch: any, callEndpoint: any) => {
    const { data } = await callEndpoint(filterMeetingsPerServiceAndDate(start, end, serviceCode, token));
    const meetingsQueue: IMeetingQueueStatusResponse[] = await getMeetingsFromQueue(area, serviceCode, token, callEndpoint);

    const eventResponse: IEventDetailResponse[] | undefined = data;
    if(eventResponse instanceof Object){
        const result: IEventDetailAdapter[] = eventResponse.map(
            (event: IEventDetailResponse) => {
                if (Array.isArray(meetingsQueue)) {
                    const assignedTo = meetingsQueue.find(meeting => meeting.meetingID === event.code)?.assignedTo
                    return createEventDetailAdapter(event, area, assignedTo)
                }
                return createEventDetailAdapter(event, area);
            });

        const eventResult = result.sort((a, b) => new Date(a.Start) - new Date(b.Start));

        return eventResult;
    }

    return false;
}

export const getUsersFromArea = async (area: string, token: string, dispatch: any, callEndpoint: any) => {
    const presenceResponse: IPresenceResponse[] = await getAllUsersPresence(token, dispatch, callEndpoint);

    const { data } = await callEndpoint(getUsersFromAreaCode(area, token));
    const usersResponse: IUsersResponse[] = data;

    const result: IUsersAdapter[] = usersResponse.map((user: IUsersResponse) => {
        const userPresence = presenceResponse.find(presence => presence.id === user.code);
        
        return createUsersAdapter(user, userPresence?.activity)
    });

    return result;
}

export const getUsersFromService = async (service: string, token: string, dispatch: any, callEndpoint: any) => {
    const presenceResponse: IPresenceResponse[] = await getAllUsersPresence(token, dispatch, callEndpoint);

    const { data } = await callEndpoint(getUsersFromServiceCode(service, token));
    const usersResponse: IUsersResponse[] = data;

    const result: IUsersAdapter[] = usersResponse.map((user: IUsersResponse) => {
        const userPresence = presenceResponse.find(presence => presence.id === user.code);
        
        return createUsersAdapter(user, userPresence?.activity)
    });

    return result;
}

export const updateUsersService = async (service: string, users: IUsersAdapter[], token: string, dispatch: any, callEndpoint: any) => {
    users.map(async (user) => await callEndpoint(putUsersService(service, user.Code, token)))
}

export const removeMeetingForUser = async (area: string, meeting: string, user: string, token: string, dispatch: any, callEndpoint: any) => {
    const { data } = await callEndpoint(discardMeeting(area, meeting, user, token))

    return data;
}

export const assignMeetingFromArea = async (area: string, meeting: string, user: string, token: any, dispatch: any, callEndpoint: any) => {
    
    const { status } = await callEndpoint(assignMeeting(false, area, meeting, user, token))
    if(status === 200)
        info("Se ha asignado la cita")
    else
        error("No se pudo asignar la cita")
}

export const cancelMeeting = async (meeting: string, slot: string, token: string, dispatch: any, callEndpoint: any) => await callEndpoint(deleteMeetingBySlot(meeting, slot, token))
export const discardUser = async (user: string, service: string, token: string, dispatch: any, callEndpoint: any) => await callEndpoint(deleteUserFromArea(user, service, token))
export const documentMeeting = async (meeting: string, token: string, callEndpoint: any) => await callEndpoint(downloadDocumentBySlot(meeting, token))

export const getNameEmployeeFromMeeting = (meeting: IEventDetailAdapter, users: IUsersAdapter[]) => {      
    const meetingUserId = meeting.ChangeHistory.find(history => history.Type === "AssignMeetingEarly")?.Info || meeting.AssignedTo;
    try{
        const userMeeting = users.find((user: IUsersAdapter) => user.Code === meetingUserId);
    
        return userMeeting?.Name;
    }catch(error){
        return false;
    }
    
}

const getAllUsersPresence = async (token: string, dispatch: any, callEndpoint: any) => {
    const { data } = await callEndpoint(getPresence(token));
    const presenceResponse: IPresenceResponse[] = data;

    return presenceResponse;
}

// const getMeetingsFromQueue = async (area: string, service: string, token: string, callEndpoint: any) => {
//     const { data } = await callEndpoint(getMeetingsQueueStatus(area, service, token));
//     const meetingsQueueStatus: IMeetingQueueStatusResponse[] = data;

//     return meetingsQueueStatus
// }

const getMeetingsFromQueue = async (
    area: string,
    service: string,
    token: string,
    callEndpoint: any
) => {
    try {
        const { data } = await callEndpoint(getMeetingsQueueStatus(area, service, token));
        const meetingsQueueStatus: IMeetingQueueStatusResponse[] = data;
        return meetingsQueueStatus;
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 404) {
                console.error("Error 404: No se encontró la cola de reuniones.");
                return []; 
            }
            console.error("Error en la solicitud Axios:", error.message);
        } else {
            console.error("Error desconocido:", error);
        }
        
        throw error; 
    }
};



export interface IMeetingQueueStatusResponse {
    visibleFromTimeUTC:  Date;
    isVisibleToServants: boolean;
    assignedTo:          string;
    meetingID:           string;
    typeOfMeeting:       string;
    meetingURL:          string;
    createDate:          Date;
    meetingStart:        Date;
    meetingStartUTC:     Date;
    organizationCode:    string;
    areaCode:            string;
    isQueued:            boolean;
    isAssigned:          boolean;
}