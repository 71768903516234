import {
  Attendee,
  defaultAttendee,
  defaultAttendee1,
  defaultAttendee2,
  defaultAttendee3,
} from "./Attendee";
import { ChangeHistory } from "./ChangeHistory";
import { ISubjectAdapter } from "./event.models";

export type EventMeeting = {
  code: string;
  slotId: string;
  day?: string;
  start: Date; //Crec que ara arriba en format string
  end: Date;
  createDate: Date;
  lastModifiedDate: Date;
  status: string;
  videoRoomUrl: string;
  serviceCode: string;
  adjacentFiles: string[];
  attendees: Attendee[];
  observations: string;
  comment: string;
  changeHistory: ChangeHistory[];
  assignedTo?: string;
  url?: string;
  isDocumentationAvailable?: boolean;
  subjects1?: ISubjectAdapter[];
  subjects2?: ISubjectAdapter[];
  subjects3?: ISubjectAdapter[];
  meetingStart?: string;
};

export const defaultEventMeeting: EventMeeting = {
  code: "",
  slotId: "",
  start: new Date(),
  end: new Date(),
  createDate: new Date(),
  lastModifiedDate: new Date(),
  status: "",
  videoRoomUrl: "",
  serviceCode: "",
  attendees: [defaultAttendee],
  observations: "",
  comment: "",
  changeHistory: [],
  adjacentFiles:[]
};

export const defaultEventMeeting1: EventMeeting = {
  code: "4001",
  slotId: "20201223-0730-0800",
  start: new Date("2020-12-23T07:30:00Z"),
  end: new Date("2020-12-23T08:00:00Z"),
  createDate: new Date("2020-12-18T14:00:00Z"),
  lastModifiedDate: new Date("2020-12-18T14:00:00Z"),
  status: "Pendent",
  videoRoomUrl: "https//01",
  serviceCode: "PA06",
  attendees: [defaultAttendee1],
  comment: "",
  observations: "",
  changeHistory: [],
  adjacentFiles:[]
};

export const defaultEventMeeting2: EventMeeting = {
  code: "4002",
  slotId: "20201223-0800-0830",
  start: new Date("2020-12-23T08:00:00Z"),
  end: new Date("2020-12-23T08:30:00Z"),
  createDate: new Date("2020-12-18T15:00:00Z"),
  lastModifiedDate: new Date("2020-12-18T15:00:00Z"),
  status: "Pendent",
  videoRoomUrl: "https//02",
  serviceCode: "PA06",
  attendees: [defaultAttendee2],
  observations: "",
  comment: "",
  changeHistory: [],
  adjacentFiles:[]
};

export const defaultEventMeeting3: EventMeeting = {
  code: "4003",
  slotId: "20201223-0830-0900",
  start: new Date("2020-12-23T08:30:00Z"),
  end: new Date("2020-12-23T09:00:00Z"),
  createDate: new Date("2020-12-18T16:00:00Z"),
  lastModifiedDate: new Date("2020-12-18T16:00:00Z"),
  status: "Pendent",
  videoRoomUrl: "https//03",
  serviceCode: "PA06",
  attendees: [defaultAttendee3],
  observations: "",
  comment: "",
  changeHistory: [],
  adjacentFiles:[]
};
