import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
type Services = {
  key: number;
  value: string;
  text: string;
};
interface Props {
  header: string;
  services: Services[];
  serviceSelect: string;
  onChange: (x: any, y?: any) => void;
  classNameContainer?: string;
  styleContainer?: any;
  multiple?: boolean;
}

export default (props: Props) => {
  return (
    <div
      className={`d-col px-0 mt-2 align-items-center mr-2 ${
        props.classNameContainer || ""
      }`}
      style={props.styleContainer}
    >
      <label>{props.header}</label>

      <Dropdown
        className={"dropdown-area br04 w-100"}
        selection
        multiple={props.multiple}
        clearable
        options={props.services}
        onChange={props.onChange}
        value={props.serviceSelect}
      />
    </div>
  );
};
