import React, {
  FC,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Message,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import {
  getReservedMeetings,
  cleanDeleteSlots,
  selectDeleteSlots,
  SlotTableDelete,
} from "../../../assets/slots";
import {
  fetchGetSlotHasEvent,
  selectEventWithMeetings,
} from "../../../assets/events";
import { AuthContext } from "../../../context/AuthContext";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import StateContainer from "../../StateContainer";
import { Slot } from "../../../models/Slot";
import { setResponsiveMode } from "office-ui-fabric-react/lib/utilities/decorators/withResponsiveMode";
import { date } from "yup/lib/locale";
import { RiContactsBookLine } from "react-icons/ri";
import i18n from "../../../configs/i18n";
import {fetchDeleteSlotById} from "../../../assets/slots.ts"
interface BOTableDisponibilitatSlotDeleteProps {
  serviceCode: string;
  slotId: string;
  date: string;
  openDelete: boolean;
  handleDelete: Function;
  onSlotDeleted: (slotId: string) => void; // New callback prop
}

const BOTableDisponibilitatSlotDelete: FC<BOTableDisponibilitatSlotDeleteProps> = ({
  serviceCode,
  slotId,
  date,
  openDelete,
  handleDelete,
  onSlotDeleted, // Destructure the new prop
}: BOTableDisponibilitatSlotDeleteProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;

  const handleCancelConfirmEvent = () => {
    handleDelete(false);
  };

  const handleConfirmEvent = async (force: boolean) => {
    let deleteResponse = await dispatch(fetchDeleteSlotById(serviceCode, slotId, force, token));

    if(deleteResponse.response){
      onSlotDeleted(slotId); // Call the callback to notify the parent
      handleDelete(false);
      toast((
        `${t("message.availability.delete.success_01")}`
      ), { type: "success" });
    }
    else{
      toast(t("message.availability.delete.error"), { type: "error" });
      handleDelete(false);
          }
  };

  return (
    <Modal
      id={"modal-delete-reserved"}
      size={"tiny"}
      open={openDelete}
      onClose={handleCancelConfirmEvent}
    >
      <Modal.Header>{t("message.availability.delete.title_cite")}</Modal.Header>
      <Modal.Content className={"br0"}>
        <p>
          {`${t("message.availability.delete.message_cite_01")} 
            ${moment(date).format("DD")}
            ${t("message.availability.delete.message_cite_02")}
            ${moment(date).format("MM")}
            ${t("message.availability.delete.message_cite_03")}
            ${moment(date).format("YYYY")}
            ${t("message.availability.delete.message_cite_04")}
          `}
        </p>
        <Message size="mini">
          <p>{t("message.availability.delete.message_delete_01")}</p>
          <p>{t("message.availability.delete.message_delete_02")}</p>
        </Message>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleCancelConfirmEvent} negative>
          {t("btn.cancel")}
        </Button>
        <Button
          onClick={() => {
            handleConfirmEvent(false);
          }}
          positive
        >
          {t("btn.delete_availability")}
        </Button>
        <Button
          onClick={() => {
            handleConfirmEvent(true);
          }}
          positive
        >
          {t("btn.delete_all")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BOTableDisponibilitatSlotDelete;