import React, { useContext, useState } from "react";
import { EntityManagement } from "./EntityManagement";
import { emailEventType } from "./types";
import { encode, decode } from "js-base64";
import { AuthContext } from "../../../context/AuthContext";
import { getAdminOrganization } from "../../../assets/api";
import {
  fetchPersonalitzationContent,
  putPersonalitzationContent,
  Personalization,
  defaultPersonalization,
  getOrganization,
  updateOrganitzation,
  putOrganizationURL,
  getOrganizationURL,
  putSmsLengthConsult,
  putOrganitzationNotification,
  getOrganitzationNotification
} from "../../../assets/personalitzation";
import {
  OrganitzationInfo,
  defaultOrganitzationInfo,
} from "../../../models/OrganitzationInfo";
import { Area } from "../../../models/Area";
import { fetchAreaPublished } from "../../../assets/areas";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";

export default (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authContext = useContext(AuthContext);
  const token = authContext.account.access_token;

  const [emailEventFields, setEmailEventFields] = useState({
    emailEventConfirmationSubject: "",
    emailEventConfirmation: "",
    emailEventCancelation: "",
    emailWithLinktoEventSubject: "",
    emailWithLinktoEvent: "",
    emailWithLinktoEventExpressSubject: "",
    emailWithLinktoEventExpress: "",
    emailWithOTPSubject: "",
    emailWithOTPBody: "",
    smsWithOTPSubject: "",
    emailEventCancelationSubject: "",
    smsWithOTPBody: "",
    smsEventConfirmation: "",
    smsEventConfirmationSubject: "",
    smsWithLinktoEvent: "",
    emailEventVideoReminderSubject: "",
    emailEventVideoReminder: "",
    smsVideoReminder: "",
    smsVideoReminderSubject: "",
    smsWithLinktoEventSubject: "",
    smsWithLinktoEventExpress: "",
    smsWithLinktoEventExpressSubject: "",
  });
  const [organitzationURL, setOrganitzationURL] = useState<string>("");
  const [organitzationURLInformers, setOrganitzationURLInformers] =
    useState<string>("");
  const [loading, setLoading] = useState(false);
  const [organitzation, setOrganitzation] = useState<string>("");
  const [orgInfo, setOrgInfo] = useState<OrganitzationInfo>(
    defaultOrganitzationInfo
  );
  const [eventType, setEvenType] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [areas, setAreas] = useState<Area[]>([]);
  const [sendExtern, setSendExtern] = useState(false);
  const [reminderTime, setReminderTime] = useState<string>("");
  const history = useHistory();
  const [sendReminders, setSendReminders] = useState(false);

  const [values, setValues, handleInputChange, reset] = useForm({
    entityOrganitzationURL: "",
    entityName: "",
    entityEmailConfirmationSubject: "",
    entityEmailConfirmationBody: "",
    entityEmailLinkSubject: "",
    entityEmailLinkBody: "",
    entitySmsConfirmationSubject: "",
    entitySmsConfirmationBody: "",
    entitySmsLinkSubject: "",
    entitySmsLinkBody: "",
    entitySmsLinkExpressSubject: "",
  });

  const onChangeFields = (x: emailEventType) => {
    setEmailEventFields({ ...emailEventFields, ...x });
  };

  function decodeMessages(emailEventFields: emailEventType) {
    setValues({
      entitySmsConfirmationSubject: decode(
        emailEventFields.smsEventConfirmationSubject
      ),
      entitySmsLinkSubject: decode(emailEventFields.smsWithLinktoEventSubject),
      entitySmsLinkExpressSubject: decode(emailEventFields.smsWithLinktoEventExpressSubject)
    });
    const decodedEventFields: any = Object.fromEntries(
      Object.entries(emailEventFields).map(([key, value]) => [
        key,
        decode(value),
      ])
    );
    onChangeFields(decodedEventFields);
  }

  const retrieveData = async (
    code: string,
    typeOfMeeting: string,
    channelType: string,
    token: string
  ) => {
    let data: Personalization = defaultPersonalization;
    try {
      const response: Response = await fetchPersonalitzationContent(
        code,
        typeOfMeeting,
        channelType,
        "ca",
        token
      );
      if (response.ok) {
        let aux = await response.json();
        if (aux.bodyData === null) aux.bodyData = "";
        if (aux.subjectData === null) aux.subjectData = "";
        data = aux;
      }
    } catch (error) {
      console.warn(error);
    }
    return data;
  };

  const updateRecordingsForOrganization = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    setIsRecording(false);

    await updateOrganitzation(organitzation, false, backoffice)
      .then((response) => response.json())
      .then(() => {
        setIsRecording(isRecording);

        areas.map((area) => {
          const { code } = area;
          dispatch(
            fetchAreaPublished(
              code,
              backoffice,
              t("area.publish.success"),
              t("area.publish.error")
            )
          );
        });
      });
  };

  const updateEmailCancelled = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailEventCancelationSubject.length !== 0 && emailEventFields.emailEventCancelation.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailEventCancelationSubject),
        bodyData: encode(emailEventFields.emailEventCancelation),
      };

      await putPersonalitzationContent(
        organitzation,
        "MeetingCancelled",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  };

  const updateSendExternNotifications = async () => {
    setSendExtern(!sendExtern);
  }

  const updateSendingReminders = async () => {
    setSendReminders(!sendReminders);
  }

  const updateOrganitzationNotification = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    await putOrganitzationNotification(
      organitzation,
      sendExtern,
      sendReminders,
      reminderTime,
      backoffice
    ).then((response) => {
      console.log(response);
    });
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const body = {
        servant: organitzationURLInformers,
        citizen: organitzationURL,
      };
      const response = await putOrganizationURL(organitzation, body, token);
      if (!response.ok) throw new Error("response bad");
    } catch (error) {
      setLoading(false);
      console.warn(error);
      toast(t("organitzation.personalitzation.error.email_confirmation"), { type: "error"});
    }
    try {
      updateEmailConfirmation();
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.email_confirmation"), { type: "error"});
    }
    try {
      await updateEmailLinkExpress().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.email_link_express"), { type: "error"});
    }

    try {
      updateEmailVideoReminder().then((response) => {
        console.log("UPDATED VIDEO REMINDER MAIL", response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.email_confirmation"), { type: "error"});
    }

    try {
      await updateEmailLink().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.email_link"), { type: "error"});
    }

    try {
      await updateSmsConfirmation().then((response) => {
        console.log("Update SMS Confirmation", response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.sms_confirmation"), { type: "error"});
    }
    try {
      await consultSmsConfirmationLength().then((response) => {
        if (!response) toast(t("organitzation.personalitzation.error.sms_length_confirmation"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }
    try {
      await updateSmsLinkExpress().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.sms_link_express"), { type: "error"});
    }
    try {
      await updateSmsLink().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.sms_link"), { type: "error"});
    }
    try {
      await consultSmsExpressLinkLength().then((response) => {
        if (!response) toast(t("organitzation.personalitzation.error.sms_length_link_express"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }
    try {
      await consultSmsLinkLength().then((response) => {
        if (!response) toast(t("organitzation.personalitzation.error.sms_length_link"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }
    try {
      await updateSmsOTP().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.sms_link"), { type: "error"});
    }
    try {
      await consultSmsOTPLength().then((response) => {
        console.log(response);
        if (!response) toast(t("organitzation.personalitzation.error.sms_length_otp"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }
    try {
      await updateEmailOTP().then((response) => {
        console.log("UPDATE EMAIL OTP", response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.mail_cancelation"), { type: "error"});
    }

    try {
      await updateEmailCancelled().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.sms_link"), { type: "error"});
    }

    try {
      await updateOrganitzationNotification().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
    }

    setLoading(false);
    toast(t("organitzation.personalitzation.confirm"), { type: "success"});

    try {
      await updateSmsVideoReminder().then((response) => {
        console.log(response);
      });
    } catch (error) {
      setLoading(false);
      toast(t("organitzation.personalitzation.error.sms_length_videoReminder"), { type: "error"});
    }

    try {
      await consultSmsVideoreminder().then((response) => {
        console.log(response);
        if (!response) toast(t("organitzation.personalitzation.error.sms_length_videoReminder"), {type: "error"});
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function updateEmailConfirmation() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailEventConfirmationSubject.length !== 0 && emailEventFields.emailEventConfirmation.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailEventConfirmationSubject),
        bodyData: encode(emailEventFields.emailEventConfirmation),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "CreateMeeting",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateEmailLinkExpress() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailWithLinktoEventExpressSubject.length !== 0 && emailEventFields.emailWithLinktoEventExpress.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithLinktoEventExpressSubject),
        bodyData: encode(emailEventFields.emailWithLinktoEventExpress),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "ExpressMeetingReminder",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }
  }

  async function updateEmailVideoReminder() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailEventVideoReminderSubject.length !== 0 && emailEventFields.emailEventVideoReminder.length !== 0){
      const bodyEmailVideoReminder = {
        subjectData: encode(emailEventFields.emailEventVideoReminderSubject),
        bodyData: encode(emailEventFields.emailEventVideoReminder),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder24",
        "Mail",
        "ca",
        bodyEmailVideoReminder,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }
  }

  async function updateEmailLink() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.emailWithLinktoEventSubject.length !== 0 && emailEventFields.emailWithLinktoEvent.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithLinktoEventSubject),
        bodyData: encode(emailEventFields.emailWithLinktoEvent),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateSmsConfirmation() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsEventConfirmation.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsEventConfirmationSubject),
        bodyData: encode(emailEventFields.smsEventConfirmation),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "CreateMeeting",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsConfirmationLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsEventConfirmation.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsEventConfirmation),
      };
      await putSmsLengthConsult(
        organitzation,
        "CreateMeeting",
        "Sms",
        bodyEmailConfirmation,
        backoffice
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function updateSmsLinkExpress() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsWithLinktoEventExpress.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithLinktoEventExpressSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEventExpress),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "ExpressMeetingReminder",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function updateSmsLink() {
    const { backoffice } = await authContext.getTokenForScopes();

    if(emailEventFields.smsWithLinktoEvent.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithLinktoEventSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEvent),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsExpressLinkLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsWithLinktoEventExpress.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEventExpress),
      };
      await putSmsLengthConsult(
        organitzation,
        "ExpressMeetingReminder",
        "Sms",
        bodyEmailConfirmation,
        backoffice
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function consultSmsLinkLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if(emailEventFields.smsWithLinktoEvent.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(values.entitySmsConfirmationSubject),
        bodyData: encode(emailEventFields.smsWithLinktoEvent),
      };
      await putSmsLengthConsult(
        organitzation,
        "MeetingReminder",
        "Sms",
        bodyEmailConfirmation,
        backoffice
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function updateSmsOTP() {
    const { backoffice } = await authContext.getTokenForScopes();

    
    if(emailEventFields.smsWithOTPBody.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithOTPSubject),
        bodyData: encode(emailEventFields.smsWithOTPBody),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "SendOtp",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });

    }

  }

  async function consultSmsOTPLength() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if (emailEventFields.smsWithOTPBody.length !== 0) {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsWithOTPSubject),
        bodyData: encode(emailEventFields.smsWithOTPBody),
      };
      await putSmsLengthConsult(
        organitzation,
        "SendOtp",
        "Sms",
        bodyEmailConfirmation,
        backoffice
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  async function updateSmsVideoReminder() {
    const { backoffice } = await authContext.getTokenForScopes();

    
    if(emailEventFields.smsVideoReminder.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsVideoReminderSubject),
        bodyData: encode(emailEventFields.smsVideoReminder),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "MeetingReminder24",
        "Sms",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  }

  async function consultSmsVideoreminder() {
    const {backoffice} = await authContext.getTokenForScopes();
    let resp = true;

    if (emailEventFields.smsVideoReminder.length !== 0) {
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.smsVideoReminderSubject),
        bodyData: encode(emailEventFields.smsVideoReminder),
      };
      await putSmsLengthConsult(
        organitzation,
        "MeetingReminder24",
        "Sms",
        bodyEmailConfirmation,
        backoffice
        ).then((response) => {
          console.log(response);
          resp = response;
          return resp;
        })
    }
    return resp;
  }

  

  async function updateEmailOTP() {
    const { backoffice } = await authContext.getTokenForScopes();
    if(emailEventFields.emailWithOTPBody.length !== 0){
      const bodyEmailConfirmation = {
        subjectData: encode(emailEventFields.emailWithOTPSubject),
        bodyData: encode(emailEventFields.emailWithOTPBody),
      };
  
      await putPersonalitzationContent(
        organitzation,
        "SendOtp",
        "Mail",
        "ca",
        bodyEmailConfirmation,
        backoffice
      ).then((response) => {
        console.log(response);
      });
    }

  }

  const historyHandleClick = () => {
    history.push("/organitzation");
  };

  const init = async () => {
    const { backoffice } = await authContext.getTokenForScopes();

    setLoading(true);
    const typeOfMeetings: string[] = [
      "CreateMeeting",
      "CreateExpressMeeting",
      "ExpressMeetingReminder",
      "MeetingReminder",
      "MeetingCancelled",
      "SendOtp",
      "MeetingReminder24",
    ];

    const channelType: string[] = ["Mail", "Sms"];

    await getAdminOrganization(backoffice)
      .then(async (response: any) => {
        if (response.ok) return await response.json();
        throw new Error("Error");
      })
      .then(async (response: any) => {
        const code = response.code;
        setOrganitzation(code);
        let bodyUrl: any = await getOrganizationURL(code, backoffice)
          .then((response) => response.json())
          .catch((e) => "");
        setOrganitzationURL(bodyUrl.citizen);
        setOrganitzationURLInformers(bodyUrl.servant);
        let infoAboutOrganization: OrganitzationInfo = await getOrganization(
          code,
          backoffice
        ).then((response) => response.json());
        setOrgInfo(infoAboutOrganization);
        setIsRecording(infoAboutOrganization.recordMeetingEnabled);
        setAreas(infoAboutOrganization.areas);

        const {
          bodyData: emailEventConfirmation,
          subjectData: emailEventConfirmationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[0],
          channelType[0],
          backoffice
        );
        const {
          bodyData: smsEventConfirmation,
          subjectData: smsEventConfirmationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[0],
          channelType[1],
          backoffice
        );
        const {
          bodyData: emailWithLinktoEventExpress,
          subjectData: emailWithLinktoEventExpressSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[2],
          channelType[0],
          backoffice
        );
        const {
          bodyData: emailWithLinktoEvent,
          subjectData: emailWithLinktoEventSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[3],
          channelType[0],
          backoffice
        );
        const {
          bodyData: smsWithLinktoEventExpress,
          subjectData: smsWithLinktoEventExpressSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[2],
          channelType[1],
          backoffice
        );
        const {
          bodyData: smsWithLinktoEvent,
          subjectData: smsWithLinktoEventSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[3],
          channelType[1],
          backoffice
        );
        const {
          bodyData: emailEventCancelation,
          subjectData: emailEventCancelationSubject,
        } = await retrieveData(
          code,
          typeOfMeetings[4],
          channelType[0],
          backoffice
        );
        const { 
          bodyData: emailWithOTPBody,
          subjectData: emailWithOTPSubject 
        } = await retrieveData(
            code,
            typeOfMeetings[5],
            channelType[0],
            backoffice
          );

        const { 
          bodyData: smsWithOTPBody,
          subjectData: smsWithOTPSubject 
        } = await retrieveData(
            code,
            typeOfMeetings[5],
            channelType[1],
            backoffice
          );
          const {
            bodyData: emailEventVideoReminder,
            subjectData: emailEventVideoReminderSubject,
          } = await retrieveData(
            code,
            typeOfMeetings[6],
            channelType[0],
            backoffice
          );
          const {
            bodyData: smsVideoReminder,
            subjectData: smsVideoReminderSubject,
          } = await retrieveData(
            code,
            typeOfMeetings[6],
            channelType[1],
            backoffice
          );

        decodeMessages({
          smsEventConfirmationSubject,
          smsWithLinktoEventSubject,
          smsWithLinktoEventExpressSubject,
          emailEventConfirmationSubject,
          emailEventConfirmation,
          emailEventCancelationSubject,
          emailEventCancelation,
          emailWithLinktoEventSubject,
          emailWithLinktoEvent,
          emailWithLinktoEventExpressSubject,
          emailWithLinktoEventExpress,
          emailWithOTPSubject,
          smsWithOTPSubject,
          emailWithOTPBody,
          smsWithOTPBody,
          smsEventConfirmation,
          smsWithLinktoEvent,
          smsWithLinktoEventExpress,
          emailEventVideoReminder,
          emailEventVideoReminderSubject,
          smsVideoReminder,
          smsVideoReminderSubject
        });

        await getOrganitzationNotification(code, backoffice)
      .then(async (response: any) => {
        console.log("RESPONSE BO", response);
        console.log("ORG", code);
        if (response.ok) return await response.json();
        throw new Error("Error");
      })
      .then(async (response: any) => {
        setSendExtern(response.externalNotification);
        setSendReminders(response.reminder);
        setReminderTime(response.hoursForReminder);
      });

      });

      

    setLoading(false);
  };

  return (
    <EntityManagement
      {...props}
      emailEventFields={emailEventFields}
      onChangeFields={onChangeFields}
      decodeMessages={decodeMessages}
      init={init}
      loading={loading}
      values={values}
      organitzationURL={organitzationURL}
      setOrganitzationURL={setOrganitzationURL}
      organitzationURLInformers={organitzationURLInformers}
      setOrganitzationURLInformers={setOrganitzationURLInformers}
      isRecording={isRecording}
      updateRecordingsForOrganization={updateRecordingsForOrganization}
      historyHandleClick={historyHandleClick}
      sendExtern={sendExtern}
      updateSendExternNotifications={updateSendExternNotifications}
      updateSendingReminders={updateSendingReminders}
      setReminderTime={setReminderTime}
      reminderTime={reminderTime}
      handleSubmit={handleSubmit}
      sendReminders={sendReminders}
    />
  );
};
